import { DashboardService, OperationsService } from '@go-widgets/well-logs-widget';

import { OPERATIONS_LOG_TRACK_AVAILABLE } from 'src/api/consts';
import { wellLogsWidgetAgent } from 'src/api/wellLogsWidgetAgent';

import type { IServicesCollection } from 'src/packages/di';

declare module 'src/packages/di' {
  export interface ServicesCollectionMap {
    operationsService: OperationsService;
  }
}

export async function addOperationsService(di: IServicesCollection): Promise<void> {
  const operationsService = new OperationsService(new DashboardService(wellLogsWidgetAgent));
  if (OPERATIONS_LOG_TRACK_AVAILABLE) {
    await operationsService.init();
  }

  di.addSingleton('operationsService', operationsService);
}
