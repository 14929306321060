import { SettingLineLight, UsersAltLight, UserLight } from '@profgeosoft-ui/icons';
import { observable, computed, makeObservable, action, reaction } from 'mobx';

import type { WorkspaceStore } from '../Workspace.store';
import type { IComputedValue } from 'mobx';
import type { ReactNode } from 'react';

import { WellListWidgetEntity } from '../../well-list-widget/WellListWidget.entity';

export type THintData = {
  headerIcon?: ReactNode;
  buttonOptions?: {
    text?: string;
    icon?: ReactNode;
    className?: string;
    onClick(hintInst: Hint): void;
  };
  title?: string;
  description?: string;
};

export class Hint<N extends string = string> {
  private $isVisibleGetter: IComputedValue<boolean>;
  private $wasShownGetter: IComputedValue<boolean>;

  readonly name: N;
  readonly data: THintData;

  @observable private _isVisible: boolean = false;
  @observable private _wasShown: boolean = false;

  constructor(
    name: N,
    hintData: THintData,
    isVisibleGetterFn: ((hintInst: Hint) => boolean) | null,
    wasShownGetterFn: ((hintInst: Hint) => boolean) | null
  ) {
    this.name = name;
    this.data = hintData;

    const isVisibleGetter = isVisibleGetterFn
      ? isVisibleGetterFn
      : () => {
          return this._isVisible;
        };

    const wasShownGetter = wasShownGetterFn
      ? wasShownGetterFn
      : () => {
          return this._wasShown;
        };

    this.$isVisibleGetter = computed(() => isVisibleGetter(this));
    this.$wasShownGetter = computed(() => wasShownGetter(this));

    makeObservable(this);
  }

  get isVisible(): boolean {
    return this.$isVisibleGetter.get();
  }

  get wasShown(): boolean {
    return this.$wasShownGetter.get();
  }

  @action.bound
  setIsVisible(isVisible: boolean) {
    this._isVisible = isVisible;
  }

  @action.bound
  setWasShown(wasShown: boolean): void {
    this._wasShown = wasShown;
  }
}

type THintData1 = {
  name: HintNames;
  data: THintData;
  getIsVisibleGetterFn?: (
    workspaceStore: WorkspaceStore,
    hintsStore: WorkspaceHintsStore
  ) => (hintInst: Hint) => boolean;
  getWasShownGetterFn?: (workspaceStore: WorkspaceStore) => (hintInst: Hint) => boolean;
};

export enum HintNames {
  addNewTab = 'addNewTab',
  systemTabs = 'systemTabs',
  personalTabs = 'personalTabs',
  publicTabs = 'publicTabs',
  emptyTab = 'emptyTab',
  renameTab = 'renameTab',
  wellsList = 'wellsList',
  contentWidget = 'contentWidget',
}

const WORKSPACE_HINTS_DATA: THintData1[] = [
  {
    name: HintNames.addNewTab,
    data: {
      description: 'hints:newTabHint.description',
    },
    getIsVisibleGetterFn(workspaceStore: WorkspaceStore): () => boolean {
      return () => {
        return !workspaceStore.tabsEntities.length && !workspaceStore.isCreateTabModalOpen;
      };
    },
  },
  {
    name: HintNames.systemTabs,
    data: {
      title: 'hints:systemTabsHint.title',
      description: 'hints:systemTabsHint.description',
      headerIcon: <SettingLineLight />,
      buttonOptions: {
        text: 'common:Buttons.next',
        onClick(hintInst: Hint) {
          hintInst.setWasShown(true);
        },
      },
    },
    getIsVisibleGetterFn(workspaceStore) {
      return (inst) => {
        return workspaceStore.isCreateTabModalOpen && !workspaceStore.tabsEntities.length && !inst.wasShown;
      };
    },
  },
  {
    name: HintNames.publicTabs,
    data: {
      title: 'hints:publicTabsHint.title',
      description: 'hints:publicTabsHint.description',
      headerIcon: <UsersAltLight />,
      buttonOptions: {
        text: 'common:Buttons.next',
        onClick(hintInst: Hint) {
          hintInst.setWasShown(true);
        },
      },
    },
    getIsVisibleGetterFn(workspaceStore, hintsStore) {
      return (inst) => {
        return (
          workspaceStore.isCreateTabModalOpen &&
          !workspaceStore.tabsEntities.length &&
          !!hintsStore.hintsMap.get(HintNames.systemTabs)?.wasShown &&
          !inst.wasShown
        );
      };
    },
  },
  {
    name: HintNames.personalTabs,
    data: {
      title: 'hints:personalTabsHint.title',
      description: 'hints:personalTabsHint.description',
      headerIcon: <UserLight />,
      buttonOptions: {
        text: 'common:Buttons.next',
        onClick(hintInst: Hint) {
          hintInst.setWasShown(true);
        },
      },
    },
    getIsVisibleGetterFn(workspaceStore, hintsStore) {
      return (inst) => {
        return (
          workspaceStore.isCreateTabModalOpen &&
          !workspaceStore.tabsEntities.length &&
          !!hintsStore.hintsMap.get(HintNames.publicTabs)?.wasShown &&
          !inst.wasShown
        );
      };
    },
  },
  {
    name: HintNames.emptyTab,
    data: {
      description: 'hints:emptyTabHint.description',
      buttonOptions: {
        text: 'common:Buttons.itsClear',
        onClick(hintInst: Hint) {
          hintInst.setWasShown(true);
        },
      },
    },
    getIsVisibleGetterFn(workspaceStore, hintsStore) {
      return (inst) => {
        return (
          workspaceStore.isCreateTabModalOpen &&
          !workspaceStore.tabsEntities.length &&
          !!hintsStore.hintsMap.get(HintNames.personalTabs)?.wasShown &&
          !inst.wasShown
        );
      };
    },
  },
  {
    name: HintNames.renameTab,
    data: {
      description: 'hints:renameTabHint.description',
    },
    getIsVisibleGetterFn(workspaceStore) {
      return (inst) => {
        return (
          !inst.wasShown &&
          workspaceStore.tabsEntities.length === 1 &&
          !workspaceStore.actualTab?.widgets.length &&
          workspaceStore.actualTab?.templateId === null
        );
      };
    },
  },
  {
    name: HintNames.wellsList,
    data: {
      title: 'hints:wellListHint.title',
      description: 'hints:wellListHint.description',
    },
    getIsVisibleGetterFn(workspaceStore, hintsStore) {
      return (inst) => {
        return (
          !inst.wasShown &&
          workspaceStore.tabsEntities.length > 0 &&
          !workspaceStore.actualTab?.widgets.length &&
          !hintsStore.hintsMap.get(HintNames.renameTab)?.isVisible
        );
      };
    },
  },
  {
    name: HintNames.contentWidget,
    data: {
      title: 'hints:addContentWidgetHint.title',
      description: 'hints:addContentWidgetHint.description',
    },
    getIsVisibleGetterFn(workspaceStore) {
      return (inst) => {
        return (
          !inst.wasShown &&
          workspaceStore.tabsEntities.length > 0 &&
          workspaceStore.actualTab?.widgets.length === 1 &&
          workspaceStore.actualTab.widgets[0] instanceof WellListWidgetEntity &&
          !workspaceStore.newContentWidgetController
        );
      };
    },
  },
];

export class WorkspaceHintsStore {
  private readonly workspaceStore: WorkspaceStore;
  readonly hintsMap = observable.map<HintNames, Hint>();

  constructor(wsStore: WorkspaceStore) {
    this.workspaceStore = wsStore;

    makeObservable(this);
  }

  @action.bound
  init(): VoidFunction {
    WORKSPACE_HINTS_DATA.forEach(({ name, data, getIsVisibleGetterFn, getWasShownGetterFn }) => {
      const hintInst = new Hint<HintNames>(
        name,
        data,
        getIsVisibleGetterFn?.(this.workspaceStore, this) ?? null,
        getWasShownGetterFn?.(this.workspaceStore) ?? null
      );

      this.hintsMap.set(name, hintInst);
    });

    const createNewTabDisposer = reaction(
      () => this.workspaceStore.isCreateTabModalOpen,
      (isOpen) => {
        if (!isOpen) {
          [HintNames.emptyTab, HintNames.personalTabs, HintNames.publicTabs, HintNames.systemTabs].forEach(
            (hintName) => {
              this.hintsMap.get(hintName)?.setWasShown(false);
            }
          );
        }
      }
    );

    const renameTabNameDisposer = reaction(
      () => this.workspaceStore.actualTab,
      (actualTab, prevActualTab) => {
        const hint = this.hintsMap.get(HintNames.renameTab);

        if (!actualTab && !!prevActualTab) {
          hint?.setWasShown(false);
        }
      }
    );

    if (!!this.workspaceStore.tabsEntities.length) {
      this.hintsMap.get(HintNames.renameTab)?.setWasShown(true);
    }

    return () => {
      createNewTabDisposer();
      renameTabNameDisposer();
    };
  }
}
