import { action, makeObservable, observable } from 'mobx';

import { BaseWidgetEntityWithWellId } from 'src/entities/widget/BaseWidgetEntityWithWellId';
import { requireService } from 'src/packages/di';

import type { WellAnalysisWidgetState } from '@go-widgets/well-logs-widget';
import type { WidgetStateParams } from '@go-widgets/well-logs-widget/dist/AnalysisWidget/types';
import type { SizeBoundaries, TWidgetOptions } from 'src/entities/widget/WidgetEntity';

interface WellLogsWidgetOptions {
  type: string;
  stateParams: Partial<WidgetStateParams>;
  wellId: number | null;
}

export type TWellAnalyseSerializedInternalState = {
  stateParams: Partial<WidgetStateParams>;
  wellId: number | null;
};

export class WellAnalyseWidgetEntity extends BaseWidgetEntityWithWellId {
  readonly sizeBoundaries: SizeBoundaries = {
    minInPixels: {
      w: 350,
      h: 300,
    },
    maxInPixels: null,
    min: {
      w: 47,
      h: 66,
    },
    max: {
      w: 256,
      h: 100,
    },
  };

  @observable state: WellAnalysisWidgetState | null = null;
  @observable type: string;
  @observable wellId: number | null;
  @observable stateParams: Partial<WidgetStateParams>;
  @observable centerTitle?: string;

  constructor(
    { type, stateParams, wellId }: WellLogsWidgetOptions,
    widgetOptions: TWidgetOptions,
    private readonly i18 = requireService('i18')
  ) {
    super(widgetOptions);

    this.type = type;
    this.wellId = wellId;
    this.stateParams = stateParams;

    makeObservable(this);
  }

  getNameT(): string {
    return 'widgets:WellAnalyse.title';
  }

  @action.bound
  setWellId(wellId: number | null): void {
    this.wellId = wellId;
  }

  @action.bound
  setStateParams(stateParams: Partial<WidgetStateParams>): void {
    this.stateParams = stateParams;
  }

  get isControlWidget(): boolean {
    return false;
  }

  get title(): string {
    return this.i18.t(this.getNameT());
  }

  @action.bound
  setState(state: WellAnalysisWidgetState | null): void {
    this.state = state;
  }

  @action.bound
  setFullscreen(value: boolean): void {
    this.isFullscreen = value;
    this.state?.setAppParams({
      fullScreen: value,
    });
  }

  @action.bound
  setCenterTitle(value: string): void {
    this.centerTitle = value;
  }

  override destroy() {
    this.state?.destroy();
  }
}
