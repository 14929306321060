import { action, comparer, computed, flow, makeObservable, observable, reaction } from 'mobx';

import { requireService } from 'src/packages/di';
import { RequestBuilder } from 'src/packages/request-builder/RequestBuilder';
import { debounce } from 'src/packages/shared/utils/debounce';
import { delay } from 'src/packages/shared/utils/delay';
import { equalsDeep } from 'src/packages/shared/utils/equalsDeep';
import { getNewAndDeletedFields } from 'src/packages/shared/utils/getNewAndDeletedElements';
import { mapWells } from 'src/serializers/wells/mapWells';

import type { GroupSelectStore } from '../group-select/GroupSelect.store';
import type { WellCollapseItemEntity } from '../well-collapse/components/well-collapse-item/WellCollapseItem.entity';
import type { IReactionDisposer, ObservableMap } from 'mobx';
import type { TWellListView } from 'src/api-types/wells.types';
import type { TCreateWidgetOptions, TGroup } from 'src/entities/tab/TabEntity';
import type { IWidgetStore } from 'src/entities/widget/IWidgetStore';
import type { WidgetEntity } from 'src/entities/widget/WidgetEntity';
import type { WellStatusMessage } from 'src/packages/well-status-service/addWellStatusService';

import { setWellIdInGroup } from '../../utils/setWellIdInGroup';
import { FilterWidgetEntity } from '../filter-widget/FilterWidget.entity';
import { serializeFilterDataSource } from '../filter-widget/FilterWidget.utils';

import { WellApi } from './api/WellList.api';
import { WellListWidgetEntity } from './WellListWidget.entity';

export class WellListWidgetStore implements IWidgetStore {
  private readonly api: WellApi;
  private readonly wellListEntity: WellListWidgetEntity;
  private readonly groupSelectStore: GroupSelectStore;

  @observable isCreatingFilterWidget: boolean = false;
  @observable isInitiated: boolean = false;
  @observable isLoading: boolean = false;
  @observable hasError: boolean = false;
  @observable filterDisposers: ObservableMap<WidgetEntity, IReactionDisposer> = observable.map();
  @observable activeKey: string[] = [];
  @observable isGroupSelectOpen: boolean = false;

  valueAt: (key: number) => HTMLDivElement | null;
  clearRefs: () => void;
  onCreateWellsFilterWidget: (options?: TCreateWidgetOptions) => void;

  constructor(
    wellListEntity: WellListWidgetEntity,
    groupSelectStore: GroupSelectStore,
    onCreateWellsFilterWidget: (options?: TCreateWidgetOptions) => void,
    valueAt: (key: number) => HTMLDivElement | null,
    clearRefs: () => void,
    private readonly notifications = requireService('notifications'),
    readonly favoritesWells = requireService('favoritesWells'),
    private readonly preloadService = requireService('preloadService'),
    private readonly wellStatusService = requireService('wellStatusService')
  ) {
    this.wellListEntity = wellListEntity;
    this.groupSelectStore = groupSelectStore;
    this.api = new WellApi();
    this.isGroupSelectOpen = wellListEntity.isGroupsDefaultOpened;

    this.valueAt = valueAt;
    this.clearRefs = clearRefs;
    this.onCreateWellsFilterWidget = onCreateWellsFilterWidget;

    makeObservable(this);
  }

  @action.bound
  setIsGroupSelectOpen(isOpen: boolean): void {
    this.isGroupSelectOpen = isOpen;
  }

  @action.bound
  private createWellsFilterWidget(groupId: string | null, state?: object): void {
    this.isCreatingFilterWidget = true;
    this.onCreateWellsFilterWidget({ groupId, state });
  }

  @computed
  get isFilterWidgetExists(): boolean {
    return this.groupSelectStore.getUsesFilter(this.wellListEntity.groupId);
  }

  @computed
  get isFilterStateEmpty(): boolean {
    const { filterState } = this.wellListEntity;

    if (!filterState) return true;

    for (const value of Object.values(filterState)) {
      if (typeof value === 'number' || (Array.isArray(value) && value.length > 0)) {
        return false;
      }
    }

    return true;
  }

  @action.bound
  onCollapseKeysChange(key: string[]): void {
    let collapsedKeys: string[] = [];

    for (const itemKey of key) {
      if (this.wellListEntity.collapsedKeys.has(itemKey)) {
        this.wellListEntity.removeCollapsedKey(itemKey);
      }
    }

    for (const itemKey of this.activeKey) {
      if (!key.includes(itemKey)) {
        collapsedKeys.push(itemKey);
      }
    }

    for (const deletedKey of collapsedKeys) {
      this.wellListEntity.addCollapsedKey(deletedKey);
    }

    this.activeKey = key;
  }

  @action.bound
  setGroupForWellList(group: TGroup | null): void {
    if (group === null) {
      return this.wellListEntity.setGroup(group);
    }

    const groupedWidgets = this.groupSelectStore.groupManager.getWidgetsByGroupId(group.id);

    const willLisWidget = groupedWidgets?.find((widget) => widget instanceof WellListWidgetEntity);

    if (willLisWidget) {
      this.notifications.showErrorMessageT('errors:wellListAlreadyExistsInGroup');
      return;
    }

    this.wellListEntity.setGroup(group.id);
  }

  @action.bound
  createFilterWidget(): void {
    const { filterState } = this.wellListEntity;

    if (!filterState) return;

    const wellsFilterOptions = {
      initialState: serializeFilterDataSource(filterState),
      state: null,
      isEditMode: false,
    };

    if (!this.wellListEntity.groupId) {
      const emptyGroupId = this.groupSelectStore.groupManager.findEmptyGroup();

      if (emptyGroupId === null) {
        this.notifications.showErrorMessageT('errors:noAvailableGroup');
        return;
      }

      this.createWellsFilterWidget(emptyGroupId, wellsFilterOptions);
      this.wellListEntity.setGroup(emptyGroupId);
    } else {
      this.createWellsFilterWidget(this.wellListEntity.groupId, wellsFilterOptions);
    }
  }

  @action.bound
  changeFilterBy(filter: number): void {
    this.wellListEntity.changeFilterBy(filter);
    this.fetchWells();
  }

  @action.bound
  changeGroupBy(groupBy: number): void {
    this.wellListEntity.changeGroupBy(groupBy);
    this.fetchWells();
  }

  @action.bound
  changeSearchValue(value: string): void {
    this.wellListEntity.changeSearchValue(value);

    this.debouncedFetchWells();
  }

  @action.bound
  selectWell(well: WellCollapseItemEntity): void {
    this.wellListEntity.setSelectedWell(well);
  }

  @action.bound
  toggleFavorite(well: WellCollapseItemEntity): void {
    const desiredWellId = this.favoritesWells.wellIds.includes(well.id);

    if (desiredWellId) {
      this.favoritesWells.disableFavorite(well);
    } else {
      this.favoritesWells.enableFavorite(well);
    }
  }

  @action.bound
  setInitiated(value: boolean): void {
    this.isInitiated = value;
  }

  @action.bound
  clearSearch(): void {
    this.wellListEntity.changeSearchValue('');

    this.debouncedFetchWells();
  }

  @action.bound
  debouncedFetchWells = debounce(() => {
    this.fetchWells();
  }, 500);

  @flow.bound
  async *fetchWells() {
    const view = this.preloadService.getPreloadedData<TWellListView>('well-list-control');

    this.clearRefs();

    try {
      this.hasError = false;
      this.isLoading = true;

      const builder = new RequestBuilder().configure((settings) => {
        settings
          .useDataSource(this.favoritesWells.dataSource, { prefix: '$' })
          .useDataSource(this.wellListEntity.createDataSource(), { prefix: '$' });
      });

      const currentGrouping = view.grouping.groups.find((_, index) => index === this.wellListEntity.groupBy);
      const currentFilter = view.filtering.filters.find((_, index) => index === this.wellListEntity.filterBy);

      if (!currentGrouping || !currentFilter) return;

      for (const request of currentGrouping.request) {
        builder.apply(request);
      }

      for (const request of currentFilter.request) {
        builder.apply(request);
      }

      for (const request of view.search.request) {
        builder.apply(request);
      }

      for (const request of view.outerFilterRequests) {
        builder.apply(request);
      }

      const { postData, getParams } = builder.build();

      if (typeof postData !== 'object' || !postData) return;

      const wells = await this.api.getWells(postData, getParams);

      yield;

      const _wells = wells ? mapWells(wells, view, this.wellListEntity.groupBy) : [];

      this.wellListEntity.setWells(_wells);

      if (this.wellListEntity.selectedWellID) {
        this.scrollWellIntoView(this.wellListEntity.selectedWellID);
      }

      this.activeKey = _wells.map((well) => well.id).filter((key) => !this.wellListEntity.collapsedKeys.has(key));
    } catch (error) {
      yield;

      console.error(error);
      this.hasError = true;
      this.notifications.showErrorMessageT('errors:failedToLoadWells');
    } finally {
      this.isLoading = false;
    }
  }

  @flow.bound
  private async *scrollWellIntoView(wellId: number) {
    let selectedWell: WellCollapseItemEntity | undefined;

    const collapse = this.wellListEntity.wells.find((collapse) => {
      const item = collapse.items.find((item) => item.id === wellId);

      if (item) {
        selectedWell = item;
      }

      return !!item;
    });

    if (!collapse || !selectedWell) return;

    if (!this.activeKey.includes(collapse.name) && Array.isArray(this.activeKey)) {
      this.activeKey.push(collapse.name);
      yield;
      await delay(100);
      yield;
    }

    const wellRef = this.valueAt(selectedWell.id);

    if (wellRef) {
      wellRef.scrollIntoView({ block: 'nearest' });
    }
  }

  @action.bound
  findAndScrollToWell(wellId: number): void {
    let selectedWell: WellCollapseItemEntity | undefined;

    for (const collapse of this.wellListEntity.wells) {
      const item = collapse.items.find((item) => item.id === wellId);

      if (item) {
        selectedWell = item;
        break;
      }
    }

    const view = this.preloadService.getPreloadedData<TWellListView>('well-list-control');
    const { defaultSearchFilterIndex } = view.filtering;

    if (!selectedWell && this.wellListEntity.filterBy !== defaultSearchFilterIndex) {
      this.changeFilterBy(defaultSearchFilterIndex);
    } else if (selectedWell) {
      this.scrollWellIntoView(selectedWell.id);
    }
  }

  @action.bound
  private mapNewWidgets(widgets: WidgetEntity[], shouldRestoreFilterState?: boolean): void {
    for (const widget of widgets) {
      if (widget instanceof FilterWidgetEntity && !this.filterDisposers.has(widget)) {
        if (shouldRestoreFilterState && !this.isCreatingFilterWidget) {
          widget.resetAll();

          if (this.wellListEntity.filterState) {
            const newInitialState = serializeFilterDataSource(this.wellListEntity.filterState);

            widget.setInitialState(newInitialState);

            if (newInitialState.length !== 0) {
              widget.mapInitialState();
            }
          }
        }

        const disposeFilterWidget = reaction(
          () => widget.initialData,
          (state) => {
            if (!this.isCreatingFilterWidget) {
              this.wellListEntity.setOuterState(state);
            }

            this.isCreatingFilterWidget = false;
          },
          { fireImmediately: true }
        );

        this.filterDisposers.set(widget, disposeFilterWidget);
      }
    }
  }

  private mapDeletedWidgets(widgets: WidgetEntity[]): void {
    for (const widget of widgets) {
      if (this.filterDisposers.has(widget)) {
        const disposer = this.filterDisposers.get(widget);

        disposer?.();

        this.filterDisposers.delete(widget);
      }
    }
  }

  private onWellStatusChange = (wellStatusMessage: WellStatusMessage): void => {
    if (wellStatusMessage.status === 'COMPLETED') {
      this.debouncedFetchWells();
    } else {
      this.wellListEntity.updateWellStatus(wellStatusMessage.wellId, wellStatusMessage.status);
    }
  };

  effect = () => {
    this.wellStatusService.subscribe('/wellStatus', this.onWellStatusChange);

    const disposeGroupId = reaction(
      () => this.wellListEntity.groupId,
      (groupId, prevGroupId) => {
        if (!groupId && prevGroupId) {
          this.wellListEntity.setSelectedWell(null);
        }

        if (groupId === null) return;

        const widgets = this.groupSelectStore.groupManager.getWidgetsByGroupId(groupId);
        setWellIdInGroup(widgets, this.wellListEntity);
      },
      { fireImmediately: true }
    );

    const disposeOuterState = reaction(
      () => this.wellListEntity.filterState,
      (newState, prevState) => {
        if (!equalsDeep(newState, prevState)) {
          this.fetchWells();
        }
      }
    );

    const dispose = reaction(
      () => ({
        widgets: this.groupSelectStore.groupManager.getWidgetsByGroupId(this.wellListEntity.groupId).slice(),
        groupId: this.wellListEntity.groupId,
      }),
      ({ widgets, groupId }, prevState) => {
        const oldWidgets = prevState?.widgets;
        const oldGroupId = prevState?.groupId;

        const prevWidgets = oldWidgets ?? [];

        const { newElements: newWidgets, deletedElements: deletedWidgets } = getNewAndDeletedFields<WidgetEntity>(
          widgets,
          prevWidgets
        );

        const shouldRestoreFilterState = oldGroupId === groupId;

        this.mapNewWidgets(newWidgets, shouldRestoreFilterState);
        this.mapDeletedWidgets(deletedWidgets);

        if (widgets.length === 0 && !this.isInitiated) {
          this.fetchWells();
        }
      },
      { fireImmediately: true, equals: comparer.shallow }
    );

    const disposeWell = reaction(
      () => ({ wellId: this.wellListEntity.selectedWellID }),
      ({ wellId }) => {
        if (!wellId) return;

        this.findAndScrollToWell(wellId);
      },
      { fireImmediately: true }
    );

    this.setInitiated(true);

    return () => {
      this.wellStatusService.unsubscribe('/wellStatus', this.onWellStatusChange);

      disposeGroupId();
      disposeOuterState();
      disposeWell();
      dispose();
    };
  };
}
