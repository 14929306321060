import { Loader } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';

import { MainLayout } from 'src/components/layouts/main-layout/MainLayout';

import type { FC } from 'react';

import { SettingsGrid } from './features/settings-grid/SettingsGrid';
import { SidebarButton } from './features/sidebar-button/SidebarButton';

export const SettingsPage: FC = observer(function SettingsPage() {
  const isLoading = false;

  const renderContent = () => {
    if (isLoading) {
      return <Loader size="l" />;
    }

    return (
      <>
        <SettingsGrid />
        <SidebarButton />
      </>
    );
  };

  return <MainLayout title="Settings">{renderContent()}</MainLayout>;
});
