import { OperationalParamsPanel } from '@go-widgets/well-logs-widget';
import { EditLightSmall, SoundMaxLight, SoundMuteLight } from '@profgeosoft-ui/icons';
import { Button, Tooltip } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useService } from 'src/packages/di';

import type { OperationalParametersWidgetEntity } from './OperationalParametersWidget.entity';
import type { TWidgetRendererOptions } from '../workspace/services/WidgetRendererService';

import { NoWellChosen } from '../no-well-chosen';
import { WidgetHeader } from '../widget-header/WidgetHeader';

import { OperationalParametersWidgetStore } from './OperationalParametersWidget.store';

import styles from './OperationalParametersWidget.module.scss';

type WrapperProps = {
  entity: OperationalParametersWidgetEntity;
  options: TWidgetRendererOptions;
};

type Props = {
  store: OperationalParametersWidgetStore;
  entity: OperationalParametersWidgetEntity;
  options: TWidgetRendererOptions;
};

export const OperationalParametersWidgetWrapper = observer(function OperationalParametersWidgetWrapper({
  entity,
  options,
}: WrapperProps) {
  const [store, setStore] = useState<OperationalParametersWidgetStore | null>(null);

  const storeService = useService('widgetStoreService');

  useEffect(() => {
    if (store) return;

    const _store = storeService.getStore<OperationalParametersWidgetEntity, OperationalParametersWidgetStore>(entity);

    if (_store) {
      setStore(_store);
      if (_store.groupSelectStore == null) {
        _store.setGroupSelectStore(options.groupSelectStore);
      }
    } else {
      const newStore = new OperationalParametersWidgetStore(entity, options.groupSelectStore);

      storeService.setStore<OperationalParametersWidgetEntity, OperationalParametersWidgetStore>(entity, newStore);

      setStore(newStore);
    }
  }, [setStore, storeService, entity, options.groupSelectStore, store]);

  if (!store) return null;

  return <OperationalParametersWidget store={store} entity={entity} options={options} />;
});

export const OperationalParametersWidget = observer(function OperationalParametersWidget({
  entity,
  options,
  store,
}: Props) {
  const { setGroupForWellLogs, isReady, effect, setEditOpen } = store;

  const { t } = useTranslation();

  const { wellId, isFullscreen, state, enableSound, toggleWidgetSound } = entity;

  useEffect(effect, [effect]);

  const directories = useService('directories');
  const wells = directories.getObject('GOdashboard_Well');
  const wellName = wells?.find((item) => item.id === wellId)?.data.name ?? wellId;

  return (
    <div className={styles.widgetWrapper}>
      <WidgetHeader
        entity={entity}
        onWidgetChangeGroup={setGroupForWellLogs}
        groupSelectStore={options.groupSelectStore}
        widgetName={entity.title}
        wellName={wellName}
        onWidgetDelete={() => {
          options.onWidgetDelete(entity);
        }}
        showCloseButton={!isFullscreen}
        draggable={!isFullscreen}
        widgetNameClassName={styles.operationalPanelName}
        widgetTitleClassName={styles.operationalPanelTitle}
        renderAdditionalButtons={() => {
          if (!isReady) return null;

          return (
            <>
              <Tooltip title={t('common:Widget.sound')} delay={400}>
                <Button
                  onClick={() => toggleWidgetSound()}
                  variant="flat"
                  icon={
                    enableSound ? <SoundMuteLight className={styles.icon} /> : <SoundMaxLight className={styles.icon} />
                  }
                  className={styles.button}
                />
              </Tooltip>
              <Tooltip title={t('common:Widget.edit')} delay={400}>
                <Button
                  onClick={() => setEditOpen()}
                  variant="flat"
                  icon={<EditLightSmall className={styles.icon} />}
                  className={styles.button}
                />
              </Tooltip>
            </>
          );
        }}
      />
      {state ? (
        <div className={styles.wrapper}>{state && <OperationalParamsPanel widgetState={state} />}</div>
      ) : (
        <NoWellChosen />
      )}
    </div>
  );
});
