import { DownloadAltLight, FullArrowLight as FullIcon, ReduceLight as MinimizeIcon } from '@profgeosoft-ui/icons';
import { Button, Tooltip } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useService } from 'src/packages/di';

import type { ImageBroadcastWidgetEntity } from './ImageBroadcastWidget.entity';
import type { TWidgetRendererOptions } from '../workspace/services/WidgetRendererService';
import type { WidgetStateParams } from '@go-widgets/well-logs-widget/dist/AnalysisWidget/types';
import type { TCreateWidgetOptions } from 'src/entities/tab/TabEntity';

import { ImageBroadcastingWidget } from '../broadcast/components/ImageBroadcastingWidget';
import { NoWellChosen } from '../no-well-chosen';
import { WidgetHeader } from '../widget-header/WidgetHeader';

import { ImageBroadcastWidgetStore } from './ImageBroadcastWidget.store';

import styles from './ImageBroadcastWidget.module.scss';

type WrapperProps = {
  entity: ImageBroadcastWidgetEntity;
  options: TWidgetRendererOptions;
  onCreateImageBroadcastWidget(
    wellId: number | null,
    stateParams: Partial<WidgetStateParams> | null,
    options?: TCreateWidgetOptions
  ): void;
};

type Props = {
  store: ImageBroadcastWidgetStore;
  entity: ImageBroadcastWidgetEntity;
  options: TWidgetRendererOptions;
};

export const ImageBroadcastWidgetWrapper = observer(function ImageBroadcastWidgetWrapper({
  entity,
  options,
  onCreateImageBroadcastWidget,
}: WrapperProps) {
  const [store, setStore] = useState<ImageBroadcastWidgetStore | null>(null);

  const storeService = useService('widgetStoreService');

  useEffect(() => {
    if (store) return;

    const _store = storeService.getStore<ImageBroadcastWidgetEntity, ImageBroadcastWidgetStore>(entity);

    if (_store) {
      setStore(_store);
    } else {
      const newStore = new ImageBroadcastWidgetStore(entity, options.groupSelectStore, onCreateImageBroadcastWidget);

      storeService.setStore<ImageBroadcastWidgetEntity, ImageBroadcastWidgetStore>(entity, newStore);

      setStore(newStore);
    }
  }, [setStore, storeService, entity, onCreateImageBroadcastWidget, options.groupSelectStore, store]);

  if (!store) return null;

  return <ImageBroadcastWidget store={store} entity={entity} options={options} />;
});

export const ImageBroadcastWidget = observer(function ImageBroadcastWidget({ entity, options, store }: Props) {
  const { setGroupForWellLogs, exitFullScreen, isReady, destroy, effect } = store;

  const { t } = useTranslation();

  const { wellId, isFullscreen, state, setFullscreen } = entity;

  useEffect(effect, [effect]);

  const directories = useService('directories');
  const wells = directories.getObject('GOdashboard_Well');
  const wellName = wells?.find((item) => item.id === wellId)?.data.name ?? wellId;

  return (
    <div className={styles.ImageBroadcastWidgetWrapper}>
      <WidgetHeader
        entity={entity}
        onWidgetChangeGroup={setGroupForWellLogs}
        groupSelectStore={options.groupSelectStore}
        widgetName={entity.title}
        wellName={wellName}
        sectionTitle={entity.centerTitle}
        onWidgetDelete={() => {
          destroy();
          options.onWidgetDelete(entity);
        }}
        showCloseButton={!isFullscreen}
        draggable={!isFullscreen}
        renderAdditionalButtons={() => {
          if (!isReady) return null;

          return (
            <>
              {entity.state && entity.state.currentImageUrl && (
                <a download={entity.state.currentImageUrl.alt + '.png'} href={entity.state.currentImageUrl.url}>
                  <Tooltip title={t('imageBroadcast:download')} delay={400}>
                    <Button
                      variant="flat"
                      icon={<DownloadAltLight className={styles.icon} />}
                      className={styles.button}
                    />
                  </Tooltip>
                </a>
              )}
              {!isFullscreen && (
                <Tooltip title={t('common:Widget.fullscreen')} delay={400}>
                  <Button
                    onClick={() => setFullscreen(true)}
                    variant="flat"
                    icon={<FullIcon className={styles.icon} />}
                    className={styles.button}
                  />
                </Tooltip>
              )}
              {isFullscreen && (
                <Tooltip title={t('common:Widget.collapse')} delay={400}>
                  <Button
                    onClick={() => exitFullScreen()}
                    variant="flat"
                    icon={<MinimizeIcon className={styles.icon} />}
                    className={styles.button}
                  />
                </Tooltip>
              )}
            </>
          );
        }}
      />
      <div className={styles.wrapper}>{state ? <ImageBroadcastingWidget widgetState={state} /> : <NoWellChosen />}</div>
    </div>
  );
});
