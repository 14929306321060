import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { useService } from 'src/packages/di';
import { useRefs } from 'src/packages/shared/hooks/useRefsArray';

import type { WellListWidgetEntity } from './WellListWidget.entity';
import type { TWidgetRendererOptions } from '../workspace/services/WidgetRendererService';
import type { WellIndexType, WidgetStateParams } from '@go-widgets/well-logs-widget';
import type { Ref } from 'react';
import type { TCreateWidgetOptions } from 'src/entities/tab/TabEntity';

import { WidgetHeader } from '../widget-header/WidgetHeader';
import { FilterButton } from '../workbench/filter-button/FilterButton';
import { PinWidgetButton } from '../workbench/pin-widget-button/PinWidgetButton';

import { WellListWidgetStore } from './WellListWidget.store';
import { WellListWidgetBody } from './WellListWidgetBody';

type Props = {
  wellListEntity: WellListWidgetEntity;
  options: TWidgetRendererOptions;
  onCreateWellsFilterWidget(options?: TCreateWidgetOptions): void;
  onCreateWellLogsWidget?(
    wellIndexType: WellIndexType,
    wellId: number | null,
    stateParams: Partial<WidgetStateParams> | null,
    options?: TCreateWidgetOptions
  ): void;
};

type WellListProps = {
  wellListEntity: WellListWidgetEntity;
  options: TWidgetRendererOptions;
  store: WellListWidgetStore;
  refAt: <U extends HTMLDivElement>(key: number) => Ref<U>;
  onCreateWellLogsWidget?(
    wellIndexType: WellIndexType,
    wellId: number | null,
    stateParams: Partial<WidgetStateParams> | null,
    options?: TCreateWidgetOptions
  ): void;
};

export const WellListWidgetWrapper = observer(function WellListWidgetWrapper({
  wellListEntity,
  options,
  onCreateWellsFilterWidget,
  onCreateWellLogsWidget,
}: Props) {
  const [store, setStore] = useState<WellListWidgetStore | null>(null);
  const { refAt, valueAt, clearRefs } = useRefs<HTMLDivElement, number>();

  const widgetStoreService = useService('widgetStoreService');

  useEffect(() => {
    const store = widgetStoreService.getStore<WellListWidgetEntity, WellListWidgetStore>(wellListEntity);

    if (store) {
      setStore(store);
    } else {
      const newStore = new WellListWidgetStore(
        wellListEntity,
        options.groupSelectStore,
        onCreateWellsFilterWidget,
        valueAt,
        clearRefs
      );

      widgetStoreService.setStore<WellListWidgetEntity, WellListWidgetStore>(wellListEntity, newStore);

      setStore(newStore);
    }
  }, [
    setStore,
    clearRefs,
    onCreateWellsFilterWidget,
    options.groupSelectStore,
    valueAt,
    wellListEntity,
    widgetStoreService,
  ]);

  if (!store) return null;

  return (
    <WellListWidget
      wellListEntity={wellListEntity}
      options={options}
      store={store}
      refAt={refAt}
      onCreateWellLogsWidget={onCreateWellLogsWidget}
    />
  );
});

export const WellListWidget = observer(function WellListWidget({
  wellListEntity,
  options,
  store,
  refAt,
  onCreateWellLogsWidget,
}: WellListProps) {
  const {
    isFilterWidgetExists,
    createFilterWidget,
    setGroupForWellList,
    isFilterStateEmpty,
    fetchWells,
    isInitiated,
    effect,
  } = store;

  useEffect(() => {
    if (!isInitiated) {
      fetchWells();
    }
  }, [isInitiated, fetchWells]);

  useEffect(effect, [effect]);

  const handlePinWidget = () => options.widgetsDisplayingPriorityManager.togglePinWidget(wellListEntity);

  return (
    <>
      <WidgetHeader
        entity={wellListEntity}
        onWidgetChangeGroup={setGroupForWellList}
        groupSelectStore={options.groupSelectStore}
        widgetName={wellListEntity.title}
        onWidgetDelete={() => options.onWidgetDelete(wellListEntity)}
        renderAdditionalButtons={() => (
          <>
            {!isFilterStateEmpty && !isFilterWidgetExists && <FilterButton onClick={createFilterWidget} />}
            {wellListEntity.isControlWidget && (
              <PinWidgetButton isPinned={wellListEntity.isPinned} onClick={handlePinWidget} />
            )}
          </>
        )}
      />
      <WellListWidgetBody
        store={store}
        wellListEntity={wellListEntity}
        refAt={refAt}
        onCreateWellLogsWidget={onCreateWellLogsWidget}
      />
    </>
  );
});
