import type { TRenderWidget, TWidgetRendererOptions } from './WidgetRendererService';
import type { VideobroadcastWidgetEntity } from '../../videobroadcast-widget/VideobroadcastWidget.entity';

import { VideobroadcastWidgetWrapper } from '../../videobroadcast-widget/VideobroadcastWidget';

export function renderVideobroadcastWidget(
  widget: VideobroadcastWidgetEntity,
  options: TWidgetRendererOptions
): TRenderWidget {
  return ({ onCreateVideobroadcastWidget }) => (
    <VideobroadcastWidgetWrapper
      entity={widget}
      options={options}
      onCreateVideobroadcastWidget={onCreateVideobroadcastWidget}
    />
  );
}
