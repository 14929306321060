import type {
  TVideobroadcastSerializedInternalState,
  VideobroadcastWidgetEntity,
} from '../../../pages/dashboard/features/videobroadcast-widget/VideobroadcastWidget.entity';

export function serializeVideoBroadcastTemplate(widget: VideobroadcastWidgetEntity) {
  const internalState: TVideobroadcastSerializedInternalState = {
    wellId: widget.wellId,
    stateParams: widget.stateParams,
  };
  return {
    uuid: widget.uuid,
    type: widget.type,
    groupId: widget.groupId,
    size: {
      w: widget.size.w,
      h: widget.size.h,
    },
    position: {
      x: widget.position.x,
      y: widget.position.y,
    },
    internalState,
  };
}
