import {
  AddSquareLightBig as AddIcon,
  FilterAltLight as FilterIcon,
  FullArrowLight as FullIcon,
  SubttasksLight as SubstacksIcon,
  WidgetAddLight as WidgetAddIcon,
} from '@profgeosoft-ui/icons';
import { Button, Tooltip, Hint, HINT_DEFAULT_STYLE_OPTIONS } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { TabsMenu } from 'src/pages/dashboard/features/tabs/components/tabs-menu/TabsMenu';

import type { WorkspaceHintsStore } from '../workspace/services/WorkspaceHintsStore';
import type { WorkspaceStore } from '../workspace/Workspace.store';
import type { TabEntity } from 'src/entities/tab/TabEntity';

import { WellListWidgetAsPopup } from '../well-list-widget-as-popup/WellListWidgetAsPopup';
import { HintNames } from '../workspace/services/WorkspaceHintsStore';

import styles from './Toolbar.module.scss';

type Props = {
  workspaceStore: WorkspaceStore;
  workspaceHintsStore: WorkspaceHintsStore;
  tabsEntities: TabEntity[];
  onSetActiveKey(key?: string): void;
  onCreateTab(): void;
  onRemoveTab(id: string): void;
  onTabClose?(id: string): void;
  onOpenInNewWindow?(tabId: string): void;
  onFullscreen: VoidFunction;
  onChangeTabName(id: string, name: string): void;
  onSaveTabAsTemplateModalOpen(): void;
  onTabSavedAsTemplateChange(tab: TabEntity | null): void;
  onUpdateTemplate(tab: TabEntity): void;
  disabledTabs: string[];
  activeKey?: string;
  onTabFocus?(id: string): void;
  onCreateWellListWidget?(): void;
  onCreateWellsFilterWidget?(): void;
  onCreateContentWidget: VoidFunction;
};

export const Toolbar = observer(function Toolbar({
  workspaceStore,
  workspaceHintsStore,
  activeKey,
  tabsEntities,
  disabledTabs,
  onSaveTabAsTemplateModalOpen,
  onTabSavedAsTemplateChange,
  onUpdateTemplate,
  onFullscreen,
  onTabFocus,
  onSetActiveKey,
  onCreateTab,
  onRemoveTab,
  onTabClose,
  onChangeTabName,
  onCreateWellListWidget,
  onCreateWellsFilterWidget,
  onCreateContentWidget,
  onOpenInNewWindow,
}: Props) {
  const { t } = useTranslation();

  const { isWellListBlockedInFSMode, isWellListInFSModeOpen } = workspaceStore;

  const addNewTabHint = workspaceHintsStore.hintsMap.get(HintNames.addNewTab);
  const wellsListHint = workspaceHintsStore.hintsMap.get(HintNames.wellsList);
  const contentWidgetHint = workspaceHintsStore.hintsMap.get(HintNames.contentWidget);

  return (
    <div className={styles.toolbar}>
      <div className={styles.tabsButtons}>
        <Tooltip.Multi
          tooltips={[
            {
              key: 'addNewTabTooltip',
              title: t('dashboard:Toolbar.addNewTab'),
              delay: 400,
              trigger: addNewTabHint?.isVisible ? [] : undefined,
            },
            {
              key: 'addNewTabHint',
              title: <Hint.HintContent description={t(addNewTabHint?.data.description ?? '')} />,
              open: !!addNewTabHint?.isVisible,
              ...HINT_DEFAULT_STYLE_OPTIONS,
            },
          ]}
        >
          <Button
            variant="flat"
            icon={<AddIcon className={styles.icon} />}
            onClick={onCreateTab}
            className={styles.addTabButton}
          />
        </Tooltip.Multi>

        <TabsMenu
          disabledTabs={disabledTabs}
          onTabFocus={onTabFocus}
          onOpenInNewWindow={onOpenInNewWindow}
          activeKey={activeKey}
          tabs={tabsEntities}
          onTabRemove={onRemoveTab}
          onTabClose={onTabClose}
          onActiveKeyChange={onSetActiveKey}
          onChangeTabName={onChangeTabName}
          onSaveTabAsTemplateModalOpen={onSaveTabAsTemplateModalOpen}
          onUpdateTemplate={onUpdateTemplate}
          onTabSavedAsTemplateChange={onTabSavedAsTemplateChange}
        />
      </div>
      <div className={styles.widgetsToolbar}>
        <Tooltip title={t('dashboard:Toolbar.enableFullScreen')} delay={400}>
          <Button
            variant="flat"
            icon={<FullIcon className={styles.icon} />}
            title={t('dashboard:Toolbar.enableFullScreen')}
            onClick={onFullscreen}
            className={styles.toolbarIcon}
          />
        </Tooltip>

        <Tooltip.Multi
          tooltips={[
            {
              key: 'wellListTooltip',
              trigger: wellsListHint?.isVisible || isWellListBlockedInFSMode || isWellListInFSModeOpen ? [] : undefined,
              title: t('dashboard:Toolbar.openWellsList'),
              delay: 400,
              open: wellsListHint?.isVisible || isWellListBlockedInFSMode || isWellListInFSModeOpen ? false : undefined,
            },
            {
              key: 'blockedWellListHint',
              trigger: isWellListBlockedInFSMode ? undefined : [],
              title: t('hints:blockedWellListHint.title'),
              open: isWellListBlockedInFSMode ? undefined : false,
            },
            {
              key: 'wellListHint',
              title: (
                <Hint.HintContent
                  title={t(wellsListHint?.data.title ?? '')}
                  description={t(wellsListHint?.data.description ?? '')}
                />
              ),
              open: wellsListHint?.isVisible && !isWellListInFSModeOpen && !isWellListBlockedInFSMode,
              ...HINT_DEFAULT_STYLE_OPTIONS,
            },
            {
              key: 'wellListWidgetAsPopup',
              title: <WellListWidgetAsPopup workspaceStore={workspaceStore} />,
              open: isWellListInFSModeOpen && !isWellListBlockedInFSMode,
              withArrow: false,
              gap: 8,
              className: styles.wellListPopup,
            },
          ]}
        >
          <Button
            variant={isWellListInFSModeOpen ? 'primary' : 'flat'}
            icon={<SubstacksIcon className={styles.icon} />}
            onClick={() => onCreateWellListWidget?.()}
            disabled={isWellListBlockedInFSMode}
            className={clsx(styles.toolbarIcon)}
          />
        </Tooltip.Multi>
        <Tooltip title={t('dashboard:Toolbar.openFilters')} delay={400}>
          <Button
            variant="flat"
            icon={<FilterIcon className={styles.icon} />}
            onClick={() => onCreateWellsFilterWidget?.()}
            className={styles.toolbarIcon}
          />
        </Tooltip>
        <Tooltip.Multi
          tooltips={[
            {
              key: 'contentWidgetTooltip',
              trigger: contentWidgetHint?.isVisible ? [] : undefined,
              title: t('dashboard:Toolbar.addNewWidget'),
              delay: 400,
            },
            {
              key: 'contentWidgetHint',
              title: (
                <Hint.HintContent
                  title={t(contentWidgetHint?.data.title ?? '')}
                  description={t(contentWidgetHint?.data.description ?? '')}
                />
              ),
              placement: 'bottom-end',
              open: contentWidgetHint?.isVisible,
              ...HINT_DEFAULT_STYLE_OPTIONS,
              arrowOptions: {
                ...HINT_DEFAULT_STYLE_OPTIONS.arrowOptions,
              },
            },
          ]}
        >
          <Button
            variant="flat"
            icon={<WidgetAddIcon className={styles.icon} />}
            onClick={onCreateContentWidget}
            className={styles.toolbarIcon}
          />
        </Tooltip.Multi>
      </div>
    </div>
  );
});
