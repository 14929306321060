import { action, computed, makeObservable, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import {
  DIRECTIONAL_DRILLING_AVAILABLE,
  OPERATIONAL_PARAMETERS_AVAILABLE,
  VIDEOBROADCAST_AVAILABLE,
  WELL_ANALYSE_AVAILABLE,
  WELL_DETAILS_AVAILABLE,
} from 'src/api/consts';
import { requireService } from 'src/packages/di';
import { hasValue } from 'src/packages/shared/utils/common';
import { equalsDeep } from 'src/packages/shared/utils/equalsDeep';
import { DirectionalDrillingWidgetEntity } from 'src/pages/dashboard/features/directional-drilling-widget/DirectionalDrillingWidget.entity';
import { FilterWidgetEntity } from 'src/pages/dashboard/features/filter-widget/FilterWidget.entity';
import { parseWellsFilterInternalState } from 'src/pages/dashboard/features/filter-widget/FilterWidget.utils';
import { GroupManager } from 'src/pages/dashboard/features/group-select/GroupManager';
import { ImageBroadcastWidgetEntity } from 'src/pages/dashboard/features/image-broadcast-widget/ImageBroadcastWidget.entity';
import { OperationalParametersWidgetEntity } from 'src/pages/dashboard/features/operational-parameters-widget/OperationalParametersWidget.entity';
import { WellAnalyseWidgetEntity } from 'src/pages/dashboard/features/well-analyse-widget/WellAnalyseWidget.entity';
import { WellDetailsWidgetEntity } from 'src/pages/dashboard/features/well-details-widget/WellDetailsWidget.entity';
import { WellListWidgetEntity } from 'src/pages/dashboard/features/well-list-widget/WellListWidget.entity';
import { parseWellListInternalState } from 'src/pages/dashboard/features/well-list-widget/WellListWidget.utils';
import { WellLogsWidgetEntity } from 'src/pages/dashboard/features/well-logs-widget/WellLogsWidget.entity';
import { type TBreakpointsKeys } from 'src/pages/dashboard/features/workbench/types';
import { getDefaultLayouts, getPositionX, getPositionY } from 'src/pages/dashboard/features/workbench/utils/utils';
import { getCurrentBreakpoint } from 'src/pages/dashboard/utils/getCurrentBreakpoint';
import { mapWidget, mapWidgets } from 'src/serializers/widgets/mapWidgets';

import type { WidgetEntity, WidgetPosition, WidgetSize } from '../widget/WidgetEntity';
import type { TSerializedTab, TSerializedWidget } from '../workspace/types';
import type {
  WellIndexType,
  WidgetStateParams,
  ParametersStateParams,
  DirectionalDrillingState,
} from '@go-widgets/well-logs-widget';
import type { Layout, Layouts } from '@profgeosoft/go-react-grid-layout';

import { VideobroadcastWidgetEntity } from '../../pages/dashboard/features/videobroadcast-widget/VideobroadcastWidget.entity';

type TabOptions = {
  name: string;
  id: string;
  groups: TGroup[];
  rawWidgetsData: TSerializedWidget[];
  templateId?: number | null;
  layouts?: Layouts;
};

export type TCreateWidgetOptions = {
  state?: object;
  groupId?: string | null;
  size?: WidgetSize;
  position?: WidgetPosition;
  isGroupsDefaultOpened?: boolean;
};

export type TGroup = {
  id: string;
  color: string;
};

const MAX_WIDGETS_COUNT = 20;

export class TabEntity {
  readonly groupManager: GroupManager;
  readonly initialLayouts: Layouts;

  @observable.ref layouts: Layouts;
  @observable name: string;
  @observable offsetTop?: number;
  @observable width: number = 0;
  @observable workbenchViewportHeight: number = 0;
  @observable workbenchContainerHeight: number = 0;
  @observable id: string;
  @observable isFocused: boolean = false;
  @observable widgets: WidgetEntity[];
  @observable shouldScrollToViewport: boolean = false;
  @observable currentBreakpoint: TBreakpointsKeys | null = null;
  @observable templateId: number | null = null;

  constructor(
    { name, id, groups, layouts, rawWidgetsData, templateId }: TabOptions,
    private readonly notifications = requireService('notifications'),
    private readonly widgetChangesApplyer = requireService('widgetChangesApplyer')
  ) {
    this.groupManager = new GroupManager(this, groups);

    this.name = name;
    this.id = id;
    this.templateId = templateId ?? null;
    this.layouts = layouts || getDefaultLayouts();
    this.initialLayouts = layouts || getDefaultLayouts();

    const widgets = mapWidgets(rawWidgetsData);

    this.widgets = widgets ?? [];

    makeObservable(this);
  }

  @computed
  get widgetsCollection(): ReadonlyMap<string, WidgetEntity> {
    const collection = new Map<string, WidgetEntity>();
    this.widgets.forEach((widget) => collection.set(widget.uuid, widget));

    return collection;
  }

  @computed
  get fullscreenWidget(): WidgetEntity | null {
    return this.widgets.find((widget) => widget.isFullscreen) ?? null;
  }

  @action.bound
  applyChanges(data: TSerializedTab): void {
    this.name = data.name;

    const newWidgets: WidgetEntity[] = [];

    data.widgets.forEach((rawWidget, index) => {
      const targetWidget = this.widgets.find((_widget) => _widget.uuid === rawWidget.uuid);

      if (targetWidget) {
        try {
          this.widgetChangesApplyer.applyChanges(targetWidget, rawWidget, index);
          newWidgets.push(targetWidget);
        } catch {
          newWidgets.push(mapWidget(rawWidget, index));
        }
      } else {
        newWidgets.push(mapWidget(rawWidget, index));
      }
    });

    this.widgets = newWidgets;

    if (data.layouts) {
      for (const breakpointKey of Object.keys(data.layouts)) {
        if (
          !this.layouts[breakpointKey]?.length ||
          !equalsDeep(this.layouts[breakpointKey], data.layouts[breakpointKey])
        ) {
          this.layouts[breakpointKey] = data.layouts[breakpointKey];
        }
      }
    }
  }

  @action.bound
  setCurrentBreakpoint(breakpoint: TBreakpointsKeys): void {
    this.currentBreakpoint = breakpoint;
  }

  @action.bound
  setOffsetTop(value: number): void {
    this.offsetTop = value;
  }

  @action.bound
  setWidth(width: number): void {
    this.width = width;
  }

  @action.bound
  setHeight(value: number): void {
    this.workbenchViewportHeight = value;
  }

  @action.bound
  setRealHeight(height: number): void {
    this.workbenchContainerHeight = height;
  }

  @action.bound
  changeFocus(value: boolean): void {
    this.isFocused = value;
  }

  @action.bound
  setName(name: string): void {
    this.name = name;
  }

  @action.bound
  setShouldScrollToViewport(value: boolean): void {
    this.shouldScrollToViewport = value;
  }

  @action.bound
  createWellListWidget(): string | null {
    return this.createWidget('well-list-control');
  }

  private getIsLimitExceeded(): boolean {
    if (this.widgets.length >= MAX_WIDGETS_COUNT) {
      this.notifications.showErrorMessageT('errors:maxNumberOfTabs');

      return true;
    }

    return false;
  }

  @action.bound
  createWellsFilterWidget(options?: TCreateWidgetOptions): string | null {
    if (this.getIsLimitExceeded()) return null;

    const defaultWidth = 47;
    const defaultHeight = 90;

    const optionalXPosition = options?.position?.x;
    const optionsYPosition = options?.position?.y;

    const parsedInternalState = options?.state ? parseWellsFilterInternalState(options.state) : null;

    const newWidget = new FilterWidgetEntity(
      {
        isEditMode: parsedInternalState?.isEditMode ?? true,
        state: parsedInternalState?.state ?? null,
        initialState: parsedInternalState?.initialState ?? null,
        type: 'filter-control',
      },
      {
        uuid: uuidv4(),
        isPinned: false,
        zIndex: 500,
        groupId: options?.groupId || null,
        isFullscreen: false,
        size: {
          w: options?.size?.w || defaultWidth,
          h: options?.size?.h || defaultHeight,
        },
        position: {
          x: hasValue(optionalXPosition)
            ? optionalXPosition
            : getPositionX(defaultWidth, getCurrentBreakpoint(this.width)),
          y: hasValue(optionsYPosition)
            ? optionsYPosition
            : getPositionY(defaultHeight, this.offsetTop, this.workbenchViewportHeight),
        },
        isGroupsDefaultOpened: options?.isGroupsDefaultOpened ?? false,
      }
    );

    this.widgets.push(newWidget);
    return newWidget.uuid;
  }

  @action.bound
  createWellLogsWidget(
    wellIndexType: WellIndexType,
    wellId: number | null,
    stateParams: Partial<WidgetStateParams> | null,
    options?: TCreateWidgetOptions
  ): void {
    if (this.getIsLimitExceeded()) return;

    const defaultWidth = 79;
    const defaultHeight = 66;
    const optionalXPosition = options?.position?.x;
    const optionsYPosition = options?.position?.y;

    const newWidget = new WellLogsWidgetEntity(
      {
        wellId,
        wellIndexType,
        isEditMode: false,
        stateParams: stateParams ?? {},
        type: 'well-logs-widget',
      },
      {
        groupId: options?.groupId || null,
        isGroupsDefaultOpened: options?.isGroupsDefaultOpened ?? false,
        uuid: uuidv4(),
        isPinned: false,
        isFullscreen: false,
        zIndex: 100,
        size: {
          w: options?.size?.w || defaultWidth,
          h: options?.size?.h || defaultHeight,
        },
        position: {
          x: hasValue(optionalXPosition)
            ? optionalXPosition
            : getPositionX(defaultWidth, getCurrentBreakpoint(this.width)),
          y: hasValue(optionsYPosition)
            ? optionsYPosition
            : getPositionY(defaultHeight, this.offsetTop, this.workbenchViewportHeight),
        },
      }
    );

    this.widgets.push(newWidget);
  }

  @action.bound
  createWellAnalyseWidget(
    wellId: number | null,
    stateParams?: Partial<WidgetStateParams> | null,
    options?: TCreateWidgetOptions
  ): string | null {
    if (this.getIsLimitExceeded() || !WELL_ANALYSE_AVAILABLE) return null;

    const defaultWidth = 79;
    const defaultHeight = 66;
    const optionalXPosition = options?.position?.x;
    const optionsYPosition = options?.position?.y;

    const newWidget = new WellAnalyseWidgetEntity(
      {
        wellId,
        stateParams: stateParams ?? {},
        type: 'well-analyse-widget',
      },
      {
        groupId: options?.groupId || null,
        isGroupsDefaultOpened: options?.isGroupsDefaultOpened ?? false,
        uuid: uuidv4(),
        isPinned: false,
        isFullscreen: false,
        zIndex: 100,
        size: {
          w: options?.size?.w || defaultWidth,
          h: options?.size?.h || defaultHeight,
        },
        position: {
          x: hasValue(optionalXPosition)
            ? optionalXPosition
            : getPositionX(defaultWidth, getCurrentBreakpoint(this.width)),
          y: hasValue(optionsYPosition)
            ? optionsYPosition
            : getPositionY(defaultHeight, this.offsetTop, this.workbenchViewportHeight),
        },
      }
    );

    this.widgets.push(newWidget);
    return newWidget.uuid;
  }

  @action.bound
  createWellDetailsWidget(wellId: number | null = null, options?: TCreateWidgetOptions): string | null {
    if (this.getIsLimitExceeded() || !WELL_DETAILS_AVAILABLE) return null;

    const defaultWidth = 79;
    const defaultHeight = 66;
    const optionalXPosition = options?.position?.x;
    const optionsYPosition = options?.position?.y;

    const newWidget = new WellDetailsWidgetEntity(
      {
        groupId: options?.groupId ?? null,
        isGroupsDefaultOpened: options?.isGroupsDefaultOpened ?? false,
        uuid: uuidv4(),
        isPinned: false,
        isFullscreen: false,
        zIndex: 100,
        size: {
          w: options?.size?.w ?? defaultWidth,
          h: options?.size?.h ?? defaultHeight,
        },
        position: {
          x: hasValue(optionalXPosition)
            ? optionalXPosition
            : getPositionX(defaultWidth, getCurrentBreakpoint(this.width)),
          y: hasValue(optionsYPosition)
            ? optionsYPosition
            : getPositionY(defaultHeight, this.offsetTop, this.workbenchViewportHeight),
        },
      },
      {
        wellId,
        section: 'info',
        isEditMode: false,
      }
    );

    this.widgets.push(newWidget);
    return newWidget.uuid;
  }

  @action.bound
  createImageBroadcastWidget(
    wellId: number | null,
    stateParams?: Partial<WidgetStateParams> | null,
    options?: TCreateWidgetOptions
  ): string | null {
    if (this.getIsLimitExceeded() || !WELL_ANALYSE_AVAILABLE) return null;

    const defaultWidth = 110;
    const defaultHeight = 80;
    const optionalXPosition = options?.position?.x;
    const optionsYPosition = options?.position?.y;

    const newWidget = new ImageBroadcastWidgetEntity(
      {
        wellId,
        stateParams: stateParams ?? {},
        type: 'image-broadcast-widget',
      },
      {
        groupId: options?.groupId || null,
        isGroupsDefaultOpened: options?.isGroupsDefaultOpened ?? false,
        uuid: uuidv4(),
        isPinned: false,
        isFullscreen: false,
        zIndex: 100,
        size: {
          w: options?.size?.w || defaultWidth,
          h: options?.size?.h || defaultHeight,
        },
        position: {
          x: hasValue(optionalXPosition)
            ? optionalXPosition
            : getPositionX(defaultWidth, getCurrentBreakpoint(this.width)),
          y: hasValue(optionsYPosition)
            ? optionsYPosition
            : getPositionY(defaultHeight, this.offsetTop, this.workbenchViewportHeight),
        },
      }
    );

    this.widgets.push(newWidget);
    return newWidget.uuid;
  }

  @action.bound
  createOperationalParametersWidget(
    wellIndexType: WellIndexType,
    wellId: number | null,
    enableSound: boolean,
    stateParams?: Partial<ParametersStateParams> | null,
    options?: TCreateWidgetOptions
  ): void {
    if (this.getIsLimitExceeded() || !OPERATIONAL_PARAMETERS_AVAILABLE) return;

    const defaultWidth = 79;
    const defaultHeight = 66;
    const optionalXPosition = options?.position?.x;
    const optionsYPosition = options?.position?.y;

    const newWidget = new OperationalParametersWidgetEntity(
      {
        wellId,
        stateParams: stateParams ?? {},
        type: 'well-operational-params-widget',
        wellIndexType,
        enableSound,
      },
      {
        groupId: options?.groupId || null,
        isGroupsDefaultOpened: options?.isGroupsDefaultOpened ?? false,
        uuid: uuidv4(),
        isPinned: false,
        isFullscreen: false,
        zIndex: 100,
        size: {
          w: options?.size?.w || defaultWidth,
          h: options?.size?.h || defaultHeight,
        },
        position: {
          x: hasValue(optionalXPosition)
            ? optionalXPosition
            : getPositionX(defaultWidth, getCurrentBreakpoint(this.width)),
          y: hasValue(optionsYPosition)
            ? optionsYPosition
            : getPositionY(defaultHeight, this.offsetTop, this.workbenchViewportHeight),
        },
      }
    );

    this.widgets.push(newWidget);
  }

  @action.bound
  createDirectionalDrillingWidget(
    wellId: number | null,
    stateParams?: Partial<DirectionalDrillingState> | null,
    options?: TCreateWidgetOptions
  ): string | null {
    if (this.getIsLimitExceeded() || !DIRECTIONAL_DRILLING_AVAILABLE) return null;

    const defaultWidth = 79;
    const defaultHeight = 66;
    const optionalXPosition = options?.position?.x;
    const optionsYPosition = options?.position?.y;

    const newWidget = new DirectionalDrillingWidgetEntity(
      {
        wellId,
        stateParams: stateParams ?? {},
        type: 'directional-drilling-widget',
      },
      {
        groupId: options?.groupId || null,
        isGroupsDefaultOpened: options?.isGroupsDefaultOpened ?? false,
        uuid: uuidv4(),
        isPinned: false,
        isFullscreen: false,
        zIndex: 100,
        size: {
          w: options?.size?.w || defaultWidth,
          h: options?.size?.h || defaultHeight,
        },
        position: {
          x: hasValue(optionalXPosition)
            ? optionalXPosition
            : getPositionX(defaultWidth, getCurrentBreakpoint(this.width)),
          y: hasValue(optionsYPosition)
            ? optionsYPosition
            : getPositionY(defaultHeight, this.offsetTop, this.workbenchViewportHeight),
        },
      }
    );

    this.widgets.push(newWidget);
    return newWidget.uuid;
  }

  @action.bound
  createVideobroadcastWidget(
    wellId: number | null,
    stateParams?: Partial<ParametersStateParams> | null,
    options?: TCreateWidgetOptions
  ): void {
    if (this.getIsLimitExceeded() || !VIDEOBROADCAST_AVAILABLE) return;

    const defaultWidth = 79;
    const defaultHeight = 66;
    const optionalXPosition = options?.position?.x;
    const optionsYPosition = options?.position?.y;

    const newWidget = new VideobroadcastWidgetEntity(
      {
        wellId,
        stateParams: stateParams ?? {},
        type: 'videobroadcast-widget',
      },
      {
        groupId: options?.groupId || null,
        isGroupsDefaultOpened: options?.isGroupsDefaultOpened ?? false,
        uuid: uuidv4(),
        isPinned: false,
        isFullscreen: false,
        zIndex: 100,
        size: {
          w: options?.size?.w || defaultWidth,
          h: options?.size?.h || defaultHeight,
        },
        position: {
          x: hasValue(optionalXPosition)
            ? optionalXPosition
            : getPositionX(defaultWidth, getCurrentBreakpoint(this.width)),
          y: hasValue(optionsYPosition)
            ? optionsYPosition
            : getPositionY(defaultHeight, this.offsetTop, this.workbenchViewportHeight),
        },
      }
    );

    this.widgets.push(newWidget);
  }

  @action.bound
  createWidget(type: string, options?: TCreateWidgetOptions): string | null {
    if (this.getIsLimitExceeded()) return null;

    if (type === 'well-list-control') {
      const defaultWidth = 37;
      const defaultHeight = 50;

      const optionalXPosition = options?.position?.x;
      const optionsYPosition = options?.position?.y;

      const parsedInternalState = options?.state ? parseWellListInternalState(options.state) : null;

      const newWidget = new WellListWidgetEntity(
        {
          groupBy: parsedInternalState?.groupBy ?? null,
          filterBy: parsedInternalState?.filterBy ?? null,
          filterState: parsedInternalState?.filterState ?? null,
          searchValue: parsedInternalState?.searchValue ?? null,
          selectedWellID: parsedInternalState?.selectedWellID ?? null,
          collapsedKeys: parsedInternalState?.collapsedKeys ?? null,
          type,
        },
        {
          uuid: uuidv4(),
          isPinned: false,
          zIndex: 500,
          groupId: options?.groupId || null,
          isFullscreen: false,
          size: {
            w: options?.size?.w || defaultWidth,
            h: options?.size?.h || defaultHeight,
          },
          position: {
            x: hasValue(optionalXPosition)
              ? optionalXPosition
              : getPositionX(defaultWidth, getCurrentBreakpoint(this.width)),
            y: hasValue(optionsYPosition)
              ? optionsYPosition
              : getPositionY(defaultHeight, this.offsetTop, this.workbenchViewportHeight),
          },
          isGroupsDefaultOpened: options?.isGroupsDefaultOpened ?? false,
        }
      );

      this.widgets.push(newWidget);
      return newWidget.uuid;
    }

    if (type === 'filter-control') {
      return this.createWellsFilterWidget(options);
    }

    if (type === 'well-details-widget') {
      return this.createWellDetailsWidget();
    }

    if (type === 'well-analyse-widget') {
      return this.createWellAnalyseWidget(null);
    }

    if (type === 'image-broadcast-widget') {
      return this.createImageBroadcastWidget(null);
    }

    if (type === 'directional-drilling-widget') {
      return this.createDirectionalDrillingWidget(null);
    }

    if (type === 'videobroadcast-widget') {
      this.createVideobroadcastWidget(null);
    }

    return null;
  }

  @action.bound
  addWidget(widget: WidgetEntity): void {
    if (this.getIsLimitExceeded()) return;

    this.widgets.push(widget);
  }

  @action.bound
  deleteWidget(widget: WidgetEntity): void {
    this.destroyWidgetEntity(widget);
    this.widgets.remove(widget);
  }

  destroyWidgetEntity(widget: WidgetEntity): void {
    if (widget.destroy) {
      widget.destroy();
    }
  }

  destroyWidgetEntities() {
    this.widgets.forEach((widget) => {
      this.destroyWidgetEntity(widget);
    });
  }

  @action.bound
  onLayoutChange(allLayouts: Layouts): void {
    this.layouts = allLayouts;
  }

  @action.bound
  getExistingWidgetLayout(uuid: string, breakpoint: TBreakpointsKeys): Layout | null {
    return this.layouts[breakpoint]?.find((widgetLayout) => widgetLayout.i === uuid) || null;
  }

  @computed
  get operationalParametersWidgetList() {
    return this.widgets.filter(isOperationalParametersWidget);
  }

  @computed
  get alarm() {
    return this.operationalParametersWidgetList.some((w) => w.hasAlarm);
  }
}

function isOperationalParametersWidget(widget: WidgetEntity): widget is OperationalParametersWidgetEntity {
  return widget.type === 'well-operational-params-widget';
}
