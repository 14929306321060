import type { TSerializedWidgetTemplate } from 'src/pages/dashboard/features/workspace/Workspace.store';

import { parseVideobroadcastInternalState } from '../../../pages/dashboard/features/videobroadcast-widget/parseVideobroadcastWidget';
import { VideobroadcastWidgetEntity } from '../../../pages/dashboard/features/videobroadcast-widget/VideobroadcastWidget.entity';

export function mapVideobroadcastTemplate(
  template: TSerializedWidgetTemplate,
  index: number
): VideobroadcastWidgetEntity {
  const { size, position } = template;

  const internalState = parseVideobroadcastInternalState(template.internalState ?? {});

  const videobroadcastWidget = new VideobroadcastWidgetEntity(
    {
      wellId: internalState.wellId,
      stateParams: internalState.stateParams,
      type: 'videobroadcast-widget',
    },
    {
      groupId: template.groupId || null,
      isGroupsDefaultOpened: false,
      uuid: template.uuid,
      isPinned: false,
      isFullscreen: false,
      zIndex: index + 1,
      size: {
        w: size.w,
        h: size.h,
      },
      position: {
        x: position.x,
        y: position.y,
      },
    }
  );

  return videobroadcastWidget;
}
