import type { TSerializedWidget } from '../../../entities/workspace/types';
import type { VideobroadcastWidgetEntity } from '../../../pages/dashboard/features/videobroadcast-widget/VideobroadcastWidget.entity';

import { parseVideobroadcastInternalState } from '../../../pages/dashboard/features/videobroadcast-widget/parseVideobroadcastWidget';

import { baseWidgetApplyChanges } from './baseWidgetApplyChanges';

export function videobroadcastApplyChanges(
  widget: VideobroadcastWidgetEntity,
  changes: TSerializedWidget,
  index: number
) {
  baseWidgetApplyChanges(widget, changes, index);

  const internalState = parseVideobroadcastInternalState(changes.internalState);

  widget.setWellId(internalState.wellId);
  widget.setStateParams(internalState.stateParams);
}
