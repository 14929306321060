import { action, makeObservable, observable } from 'mobx';

import { storage } from '../shared/consts/storage';

export interface IOtherSettingsService {
  isAltNames: boolean;
  isColoredParamsHeaders: boolean;
  selectAltNames(value: boolean): void;
  selectColoredParamsHeaders(value: boolean): void;
}

export class OtherSettingsStore {
  @observable isAltNames: boolean;
  @observable isColoredParamsHeaders: boolean;

  constructor() {
    const storedAltNames = storage.GET<boolean>('altNames');
    const storedParamsHeaders = storage.GET<boolean>('coloredParamsHeaders');
    this.isAltNames = storedAltNames != null ? storedAltNames : false;
    this.isColoredParamsHeaders = storedParamsHeaders != null ? storedParamsHeaders : false;
    makeObservable(this);
  }

  private _setAltNames(value: boolean) {
    this.isAltNames = value;
    storage.SET('altNames', value);
  }

  private _setParamsHeaders(value: boolean) {
    this.isColoredParamsHeaders = value;
    storage.SET('coloredParamsHeaders', value);
  }

  @action.bound
  selectAltNames(value: boolean) {
    this._setAltNames(value);
  }

  @action.bound
  selectColoredParamsHeaders(value: boolean) {
    this._setParamsHeaders(value);
  }
}
