import { Button, Hint, Modal, RadioButtonGroup, Scrollbar } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as TemplateImg } from 'src/packages/assets/img/template.svg';
import { requireService } from 'src/packages/di';

import type { WorkspaceHintsStore } from '../workspace/services/WorkspaceHintsStore';
import type { TGoStorageTabTemplateType, TTabTemplateWithGSProps } from '../workspace/types';

import { HintNames } from '../workspace/services/WorkspaceHintsStore';

import { calculateContainerHeight } from './CreateTabModal.utils';

import styles from './CreateTabModal.module.scss';

type Props = {
  workspaceHintsStore: WorkspaceHintsStore;
  isOpened: boolean;
  onCancel(): void;
  onConfirm(template: TTabTemplateWithGSProps): void;
  onCreateEmptyTab(): void;
};

export const CreateTabModal = observer(function CreateTabModal({
  workspaceHintsStore,
  onCancel,
  onConfirm,
  onCreateEmptyTab,
  isOpened,
}: Props) {
  const { t } = useTranslation();
  const tabTemplatesService = requireService('tabTemplates');
  const [selectedGroup, setSelectedGroup] = useState<TGoStorageTabTemplateType>('system');
  const [templatesContainerHeight, setTemplatesContainerHeight] = useState(0);
  const [transformedTabTemplates, setTransformedTabTemplates] = useState<TTabTemplateWithGSProps[][]>([]);

  const handleClose = () => {
    setSelectedGroup('system');
    onCancel();
  };

  const emptyTabHint = workspaceHintsStore.hintsMap.get(HintNames.emptyTab);
  const systemTabsHint = workspaceHintsStore.hintsMap.get(HintNames.systemTabs);
  const personalTabsHint = workspaceHintsStore.hintsMap.get(HintNames.personalTabs);
  const publicTabsHint = workspaceHintsStore.hintsMap.get(HintNames.publicTabs);

  const header = (
    <>
      <p className={styles.title}>{t('modals:CreateTabModal.title')}</p>
      <div className={styles.headerButtons}>
        <Hint
          open={!!emptyTabHint?.isVisible}
          description={t(emptyTabHint?.data.description ?? '')}
          placement="top"
          gap={10}
          buttonOptions={{
            ...emptyTabHint?.data.buttonOptions,
            text: t(emptyTabHint?.data.buttonOptions?.text ?? ''),
            onClick: () => emptyTabHint?.data.buttonOptions?.onClick(emptyTabHint),
          }}
        >
          <Button variant="flat" onClick={onCreateEmptyTab} className={styles.createEmptyButton}>
            {t('modals:CreateTabModal.createEmpty')}
          </Button>
        </Hint>
      </div>
    </>
  );

  const tabTemplates: Record<TGoStorageTabTemplateType, TTabTemplateWithGSProps[]> = {
    personal: tabTemplatesService.personal,
    public: tabTemplatesService.publicTemplates,
    system: tabTemplatesService.system,
  };

  const rawTabTemplates = tabTemplates[selectedGroup];
  const templatesContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!templatesContainerRef?.current) return;
    const rows = templatesContainerRef?.current.children;

    const containerPaddingTop = 32;

    if (!rows) return;
    const containerHeight = calculateContainerHeight(rows, containerPaddingTop, 16);

    setTemplatesContainerHeight(containerHeight);
  }, [templatesContainerRef, isOpened, transformedTabTemplates]);

  useEffect(() => {
    setTransformedTabTemplates([]);

    for (let i = 0; i < rawTabTemplates.length; i += 4) {
      const chunk = rawTabTemplates.slice(i, i + 4);

      setTransformedTabTemplates((prevState) => [...prevState, chunk]);
    }
  }, [selectedGroup, rawTabTemplates]);

  return (
    <Modal
      open={isOpened}
      className={styles.modal}
      onClickOutside={null}
      onClose={handleClose}
      title={header}
      headerClassName={styles.header}
      wrapperClassName={styles.wrapper}
    >
      <RadioButtonGroup<TGoStorageTabTemplateType>
        variant="flat"
        items={[
          {
            value: 'system',
            label: (
              <Hint
                open={!!systemTabsHint?.isVisible}
                headerIcon={systemTabsHint?.data.headerIcon}
                title={t(systemTabsHint?.data.title ?? '')}
                description={t(systemTabsHint?.data.description ?? '')}
                placement="left"
                gap={12}
                buttonOptions={{
                  ...systemTabsHint?.data.buttonOptions,
                  text: t(systemTabsHint?.data.buttonOptions?.text ?? ''),
                  onClick: () => systemTabsHint?.data.buttonOptions?.onClick(systemTabsHint),
                }}
              >
                <p>{t('modals:CreateTabModal.Tabs.system')}</p>
              </Hint>
            ),
          },
          {
            value: 'public',
            label: (
              <Hint
                open={!!publicTabsHint?.isVisible}
                headerIcon={publicTabsHint?.data.headerIcon}
                title={t(publicTabsHint?.data.title ?? '')}
                description={t(publicTabsHint?.data.description ?? '')}
                placement="top"
                gap={12}
                buttonOptions={{
                  ...publicTabsHint?.data.buttonOptions,
                  text: t(publicTabsHint?.data.buttonOptions?.text ?? ''),
                  onClick: () => publicTabsHint?.data.buttonOptions?.onClick(publicTabsHint),
                }}
              >
                <p>{t('modals:CreateTabModal.Tabs.public')}</p>
              </Hint>
            ),
          },
          {
            value: 'personal',
            label: (
              <Hint
                open={!!personalTabsHint?.isVisible}
                headerIcon={personalTabsHint?.data.headerIcon}
                title={t(personalTabsHint?.data.title ?? '')}
                description={t(personalTabsHint?.data.description ?? '')}
                placement="right"
                gap={12}
                buttonOptions={{
                  ...personalTabsHint?.data.buttonOptions,
                  text: t(personalTabsHint?.data.buttonOptions?.text ?? ''),
                  onClick: () => personalTabsHint?.data.buttonOptions?.onClick(personalTabsHint),
                }}
              >
                <p>{t('modals:CreateTabModal.Tabs.private')}</p>
              </Hint>
            ),
          },
        ]}
        value={selectedGroup}
        className={styles.switchContainer}
        itemClassName={styles.switchButton}
        onChange={setSelectedGroup}
      />

      <Scrollbar wrapperClassName={styles.wrapperScroll}>
        <div className={styles.templates} style={{ height: templatesContainerHeight }} ref={templatesContainerRef}>
          {selectedGroup &&
            transformedTabTemplates.map((tabTemplatesRow, index) => (
              <div key={index} className={styles.row}>
                {tabTemplatesRow?.map((item) => {
                  const template = item.data;
                  return (
                    <article key={item.id} className={styles.template} role="button" onClick={() => onConfirm(item)}>
                      <TemplateImg className={styles.templateImg} />
                      <div className={styles.templateTitle}>{template.template.name}</div>
                      <div className={styles.templateDescription}>{template.template.description}</div>
                    </article>
                  );
                })}
              </div>
            ))}
        </div>
      </Scrollbar>
    </Modal>
  );
});
