import common from './common.json';
import dashboard from './dashboard.json';
import directories from './directories.json';
import errors from './errors.json';
import form from './form.json';
import header from './header.json';
import hints from './hints.json';
import imageBroadcast from './image-broadcast.json';
import modals from './modals.json';
import operationalParams from './operational-params.json';
import settings from './settings.json';
import sidebar from './sidebar.json';
import wellList from './well-list.json';
import wellLogs from './well-logs.json';
import wellsFilter from './wells-filter.json';
import widgets from './widgets.json';

export default {
  header,
  dashboard,
  sidebar,
  errors,
  modals,
  common,
  wellList,
  directories,
  form,
  wellsFilter,
  widgets,
  wellLogs,
  settings,
  imageBroadcast,
  operationalParams,
  hints,
};
