import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import type { IWellCollapseItemEntity } from './WellCollapseItem.entity';
import type { ReactNode, Ref } from 'react';

import styles from './../../WellCollapse.module.scss';

type Props<T extends IWellCollapseItemEntity> = {
  item: T;
  isActive: boolean;
  renderIcon?: (item: T) => ReactNode;
  renderButtons?(): ReactNode;
  onClick(): void;
  refAt?(key: number): Ref<HTMLDivElement>;
};

export const CollapseItem = observer(function CollapseItem<T extends IWellCollapseItemEntity>({
  item,
  renderIcon,
  onClick,
  renderButtons,
  refAt,
  isActive,
}: Props<T>) {
  return (
    <div className={clsx(styles.listItem, isActive && styles.listItemActive)} onClick={onClick} ref={refAt?.(item.id)}>
      <div className={styles.titleWrapper}>
        {renderIcon?.(item)}
        <p className={styles.title}>{item.name}</p>
      </div>
      {!!renderButtons && (
        <div className={styles.buttons} onClick={(e) => e.stopPropagation()}>
          {renderButtons()}
        </div>
      )}
    </div>
  );
});
