import { action, computed, makeObservable } from 'mobx';

import type { OthersEntity } from './Others.entity';
import type { Stack } from '../../../../packages/data/structures/Stack';
import type { BaseSettingsScreen } from '../screen/BaseSettingsScreen';

import { requireService } from '../../../../packages/di';

export class OthersScreenStore {
  readonly stack: Stack<BaseSettingsScreen>;
  readonly screenEntity: OthersEntity;

  constructor(
    stack: Stack<BaseSettingsScreen>,
    entity: OthersEntity,
    private readonly otherSettings = requireService('otherSettings')
  ) {
    this.stack = stack;
    this.screenEntity = entity;
    makeObservable(this);
  }

  @computed
  get isAltNames() {
    return this.otherSettings.isAltNames;
  }

  @action.bound
  setIsAltNamesOn(value: boolean) {
    this.otherSettings.selectAltNames(value);
  }

  @computed
  get isColoredParamsHeaders() {
    return this.otherSettings.isColoredParamsHeaders;
  }

  @action.bound
  setIsColoredParamsHeaders(value: boolean) {
    this.otherSettings.selectColoredParamsHeaders(value);
  }
}
