import { action, computed, makeObservable, observable } from 'mobx';

import i18n from 'src/app/i18n';
import { requireServiceAccessor } from 'src/packages/di';
import { PRIMARY_LANG, SECONDARY_LANG, languageSwitcherItems } from 'src/packages/language/consts';

import type { ILanguageService } from './ILanguageService';
import type { LANGUAGES } from 'src/packages/language/consts';

export class LanguageStore implements ILanguageService {
  @observable language: LANGUAGES;
  @observable isLanguageSwitcherHidden = false;
  @observable primary = PRIMARY_LANG;
  secondary = SECONDARY_LANG;

  constructor(private readonly i18 = requireServiceAccessor('i18')) {
    this.language = i18n.language as LANGUAGES;

    makeObservable(this);
  }

  @action.bound
  private _setLanguage(lan: LANGUAGES) {
    this.language = lan;
  }

  @computed
  get languages() {
    const languages = [{ title: languageSwitcherItems[this.primary].text, lang: this.primary }];

    if (this.secondary && !!languageSwitcherItems[this.secondary] && this.secondary !== this.primary) {
      languages.push({ title: languageSwitcherItems[this.secondary].text, lang: this.secondary });
    }

    return languages;
  }

  @action.bound
  changePrimaryLanguage(primary: LANGUAGES) {
    this.primary = primary;
    i18n.changeLanguage(this.primary);
    this._setLanguage(this.primary);
    this.i18().init(this.primary, this.secondary);
  }

  @action.bound
  toggle(): void {
    if (!this.primary || !this.secondary) {
      return;
    }

    this._setLanguage(this.language === this.primary ? this.secondary : this.primary);
    i18n.changeLanguage(this.language);
  }

  @action.bound
  select(lang: LANGUAGES) {
    if (!this.primary || !this.secondary) {
      return;
    }
    this._setLanguage(lang);
    i18n.changeLanguage(lang);
  }

  @action.bound
  init(): void {
    if (!!this.primary && !this.secondary) {
      i18n.changeLanguage(this.primary);
      this._setLanguage(this.primary);
      this.isLanguageSwitcherHidden = true;
    }
  }
}
