import { observer } from 'mobx-react-lite';

import { ThemeStore } from 'src/app/theme/ThemeStore';
import { ReactComponent as AnalyzeIconDark } from 'src/packages/assets/icons/analyze-widget-dark.svg';
import { ReactComponent as AnalyzeIconLight } from 'src/packages/assets/icons/analyze-widget-light.svg';
import { ReactComponent as ArrowVertIcon } from 'src/packages/assets/icons/arrow-vert-directions.svg';
import { ReactComponent as BroadcastIconDark } from 'src/packages/assets/icons/broadcast-widget-dark.svg';
import { ReactComponent as BroadcastIconLight } from 'src/packages/assets/icons/broadcast-widget-light.svg';
import { ReactComponent as ClockIcon } from 'src/packages/assets/icons/clock.svg';
import { ReactComponent as DepthIcon } from 'src/packages/assets/icons/depth.svg';
import { ReactComponent as DetailIconDark } from 'src/packages/assets/icons/detail-widget-dark.svg';
import { ReactComponent as DetailIconLight } from 'src/packages/assets/icons/detail-widget-light.svg';
import { ReactComponent as DirectionalDark } from 'src/packages/assets/icons/directional-dark.svg';
import { ReactComponent as DirectionalLight } from 'src/packages/assets/icons/directional-light.svg';
import { ReactComponent as GraphIconDark } from 'src/packages/assets/icons/graph-dark.svg';
import { ReactComponent as GraphIconLight } from 'src/packages/assets/icons/graph-light.svg';
import { ReactComponent as OperationalParamsDark } from 'src/packages/assets/icons/operational-params-dark.svg';
import { ReactComponent as OperationalParamsLight } from 'src/packages/assets/icons/operational-params-light.svg';
import { ReactComponent as VideoDark } from 'src/packages/assets/icons/video-dark.svg';
import { ReactComponent as VideoLight } from 'src/packages/assets/icons/video-light.svg';

import type { TIcon } from '../NewContentWidgetModal.store';

import styles from './WidgetAndGroupPanel.module.scss';

type Props = {
  iconName?: TIcon;
};

export const PanelIcon = observer(function PanelIcon({ iconName }: Props) {
  if (!iconName) {
    return null;
  }

  const { theme } = new ThemeStore();

  switch (iconName) {
    case 'empty': {
      return null;
    }
    case 'clock': {
      return <ClockIcon />;
    }
    case 'vertArrow': {
      return <ArrowVertIcon />;
    }
    case 'depth': {
      return <DepthIcon />;
    }
    case 'detail': {
      return <div>{theme === 'dark' ? <DetailIconDark /> : <DetailIconLight />}</div>;
    }
    case 'analyze': {
      return <div>{theme === 'dark' ? <AnalyzeIconDark /> : <AnalyzeIconLight />}</div>;
    }
    case 'broadcast': {
      return <div>{theme === 'dark' ? <BroadcastIconDark /> : <BroadcastIconLight />}</div>;
    }
    case 'graph': {
      return <div className={styles.graphIconWrapper}>{theme === 'dark' ? <GraphIconDark /> : <GraphIconLight />}</div>;
    }
    case 'operational': {
      return (
        <div className={styles.graphIconWrapper}>
          {theme === 'dark' ? <OperationalParamsDark /> : <OperationalParamsLight />}
        </div>
      );
    }
    case 'directional': {
      return <div>{theme === 'dark' ? <DirectionalDark /> : <DirectionalLight />}</div>;
    }
    case 'video': {
      return <div>{theme === 'dark' ? <VideoDark /> : <VideoLight />}</div>;
    }
  }
});
