import { action, comparer, makeObservable, observable, reaction } from 'mobx';

import { requireService } from 'src/packages/di';
import { getNewAndDeletedFields } from 'src/packages/shared/utils/getNewAndDeletedElements';

import type { ImageBroadcastWidgetEntity } from './ImageBroadcastWidget.entity';
import type { WidgetStateParams } from '../broadcast/types';
import type { GroupSelectStore } from '../group-select/GroupSelect.store';
import type { IReactionDisposer, ObservableMap } from 'mobx';
import type { TCreateWidgetOptions, TGroup } from 'src/entities/tab/TabEntity';
import type { IWidgetStore } from 'src/entities/widget/IWidgetStore';
import type { WidgetEntity } from 'src/entities/widget/WidgetEntity';

import { setWellIdInGroup } from '../../utils/setWellIdInGroup';
import { ImageBroadcastingWidgetState } from '../broadcast/ImageBroadcastingWidgetState';
import { WellListWidgetEntity } from '../well-list-widget/WellListWidget.entity';

export class ImageBroadcastWidgetStore implements IWidgetStore {
  private readonly imageBroadcastEntity: ImageBroadcastWidgetEntity;
  private readonly groupSelectStore: GroupSelectStore;

  @observable isLoading: boolean = false;
  @observable isReady: boolean = false;
  @observable hasError: boolean = false;
  @observable disposers: ObservableMap<WidgetEntity, IReactionDisposer> = observable.map();
  @observable isGroupSelectOpen: boolean = false;

  onCreateImageBroadcastWidget: (
    wellId: number | null,
    stateParams: Partial<WidgetStateParams> | null,
    options?: TCreateWidgetOptions
  ) => void;

  constructor(
    imageBroadcastEntity: ImageBroadcastWidgetEntity,
    groupSelectStore: GroupSelectStore,
    onCreateImageBroadcastWidget: (
      wellId: number | null,
      stateParams: Partial<WidgetStateParams> | null,
      options?: TCreateWidgetOptions
    ) => void,
    private readonly notifications = requireService('notifications')
  ) {
    this.onCreateImageBroadcastWidget = onCreateImageBroadcastWidget;
    this.isGroupSelectOpen = imageBroadcastEntity.isGroupsDefaultOpened;

    this.imageBroadcastEntity = imageBroadcastEntity;
    this.groupSelectStore = groupSelectStore;

    makeObservable(this);
  }

  @action.bound
  setIsGroupSelectOpen(isOpen: boolean): void {
    this.isGroupSelectOpen = isOpen;
  }

  @action.bound
  exitFullScreen() {
    this.imageBroadcastEntity.setFullscreen(false);
  }

  destroy = (): void => {
    this.imageBroadcastEntity.state?.destroy();
  };

  @action.bound
  setGroupForWellLogs(group: TGroup | null): void {
    this.imageBroadcastEntity.setGroup(group ? group.id : null);
  }

  @action.bound
  setWellId(well: number | null): void {
    this.imageBroadcastEntity.setWellId(well);
  }

  private mapNewWidgets(widgets: WidgetEntity[]): void {
    for (const widget of widgets) {
      if (!(widget instanceof WellListWidgetEntity) || this.disposers.has(widget)) {
        continue;
      }

      const disposeWellListWidget = reaction(
        () => widget.selectedWellID,
        (wellId) => {
          this.imageBroadcastEntity.setWellId(wellId);
        }
      );

      this.disposers.set(widget, disposeWellListWidget);
    }
  }

  @action.bound
  private setReady(value: boolean): void {
    this.isReady = value;
  }

  private mapDeletedWidgets(widgets: WidgetEntity[]): void {
    for (const widget of widgets) {
      if (this.disposers.has(widget)) {
        const disposer = this.disposers.get(widget);

        disposer?.();

        this.disposers.delete(widget);
      }
    }
  }

  private getNewState(wellId: number): ImageBroadcastingWidgetState {
    return new ImageBroadcastingWidgetState(
      {
        // TODO: remove hardcode
        wellId,
        width: 500,
        height: 500,
        fullScreen: this.imageBroadcastEntity.isFullscreen,
      },
      this.imageBroadcastEntity.stateParams,
      {
        onInitializedSuccess: () => {
          this.setReady(true);
        },
        onInitializationFailed: (_, error) => {
          this.setReady(false);

          console.error(error);
          this.notifications.showErrorMessageT('errors:failedToInitWidget');
        },
        onInternalStateChanged: (state) => {
          this.imageBroadcastEntity.setStateParams(state);
        },
        onTitleChanged: (value) => {
          this.imageBroadcastEntity.setCenterTitle(value);
        },
      }
    );
  }

  effect = () => {
    const disposeGroupId = reaction(
      () => this.imageBroadcastEntity.groupId,
      (groupId) => {
        if (groupId === null) return;

        const widgets = this.groupSelectStore.groupManager.getWidgetsByGroupId(groupId);
        setWellIdInGroup(widgets, this.imageBroadcastEntity);
      }
    );

    const dispose = reaction(
      () => this.groupSelectStore.groupManager.getWidgetsByGroupId(this.imageBroadcastEntity.groupId).slice(),
      (widgets, prevState) => {
        const prevWidgets = prevState ?? [];

        const { newElements: newWidgets, deletedElements: deletedWidgets } = getNewAndDeletedFields<WidgetEntity>(
          widgets,
          prevWidgets
        );

        this.mapNewWidgets(newWidgets);
        this.mapDeletedWidgets(deletedWidgets);
      },
      { fireImmediately: true, equals: comparer.shallow }
    );

    const disposeWellId = reaction(
      () => this.imageBroadcastEntity.wellId,
      (wellId, prevWellId) => {
        if (wellId && !this.imageBroadcastEntity.state) {
          const newState = this.getNewState(wellId);

          this.imageBroadcastEntity.setState(newState);
        } else if (wellId && this.imageBroadcastEntity.state && typeof prevWellId !== 'undefined') {
          this.imageBroadcastEntity.state.setAppParams({ wellId });
        } else if (!wellId && this.imageBroadcastEntity.state) {
          this.imageBroadcastEntity.state.destroy();
          this.imageBroadcastEntity.setState(null);
        }
      },
      { fireImmediately: true }
    );

    return () => {
      dispose();
      disposeGroupId();
      disposeWellId();
    };
  };
}
