import { action, computed, flow, makeObservable, observable, reaction } from 'mobx';

import { requireService } from 'src/packages/di';
import { RequestBuilder } from 'src/packages/request-builder/RequestBuilder';

import type { Item } from '../controls/abstract-control-entities';
import type { GroupManager } from '../group-select/GroupManager';
import type { TGroup } from 'src/entities/tab/TabEntity';
import type { IWidgetStore } from 'src/entities/widget/IWidgetStore';
import type { WidgetEntity } from 'src/entities/widget/WidgetEntity';
import type { IDataSource } from 'src/packages/template-builder/TemplateBuilder';

import { DateRangeFieldEntity } from '../controls/entities/DateRangeField.entity';
import { FilterMultiCombobox } from '../controls/entities/FilterMultiCombobox.entity';

import { FilterWidgetApi } from './api/FilterWidget.api';
import { FilterWidgetEntity } from './FilterWidget.entity';
import { WellsFilterDataSource } from './WellsFilterDataSource';

export class FilterWidgetStore implements IWidgetStore {
  readonly api = new FilterWidgetApi();
  readonly filterWidgetEntity: FilterWidgetEntity;
  private readonly groupManager: GroupManager;

  @observable isLoading: boolean = false;
  @observable isGroupSelectOpen: boolean = false;

  onWidgetDelete: (widget: WidgetEntity, requireConfirmation?: boolean | undefined) => void;

  constructor(
    filterWidgetEntity: FilterWidgetEntity,
    groupManager: GroupManager,
    onWidgetDelete: (widget: WidgetEntity, requireConfirmation?: boolean | undefined) => void,
    private readonly notifications = requireService('notifications')
  ) {
    this.filterWidgetEntity = filterWidgetEntity;
    this.groupManager = groupManager;
    this.onWidgetDelete = onWidgetDelete;
    this.isGroupSelectOpen = filterWidgetEntity.isGroupsDefaultOpened;

    makeObservable(this);
  }

  @action.bound
  setIsGroupSelectOpen(isOpen: boolean): void {
    this.isGroupSelectOpen = isOpen;
  }

  @computed
  get isEditMode(): boolean {
    return this.filterWidgetEntity.isEditMode;
  }

  @computed
  get isResetButtonDisabled(): boolean {
    for (const item of this.filterWidgetEntity.items) {
      if (item instanceof DateRangeFieldEntity && (item.startDate || item.endDate)) {
        return false;
      }

      const { value } = item;

      if (Array.isArray(value) && value.length > 0) {
        return false;
      }
    }

    return true;
  }

  @computed
  get dataSource(): IDataSource {
    return new WellsFilterDataSource(this.filterWidgetEntity.data);
  }

  @action.bound
  setGroupForWellsFilter(group: TGroup | null): void {
    if (group === null) {
      return this.filterWidgetEntity.setGroup(group);
    }

    const groupedWidgets = this.groupManager.getWidgetsByGroupId(group.id);

    const filterWidget = groupedWidgets?.find((widget) => widget instanceof FilterWidgetEntity);

    if (filterWidget) {
      this.notifications.showErrorMessageT('errors:wellsFilterAlreadyExistsInGroup');
      return;
    }

    this.filterWidgetEntity.setGroup(group.id);
  }

  @flow.bound
  async *fetchOptions(withoutParams: boolean = false) {
    if (this.isLoading) return;

    try {
      const builder = new RequestBuilder().configure((settings) => settings.useDataSource(this.dataSource));

      this.filterWidgetEntity.view.request.forEach((request) => {
        builder.apply(request);
      });

      const { postData } = builder.build();
      const options = await this.api.fetchOptions(withoutParams || !postData ? {} : postData);

      yield;

      this.filterWidgetEntity.setOptions(options);

      this.updateForm();
    } catch (error) {
      console.error(error);
    }
  }

  private checkIsFilterMultiCombobox(control: Item, optionKey: string): boolean {
    return control instanceof FilterMultiCombobox && control.attrName === optionKey;
  }

  private checkIsStartDate(control: Item, optionKey: string): boolean {
    return control instanceof DateRangeFieldEntity && control.attrNames && control.attrNames.startDate === optionKey;
  }

  private checkIsEndDate(control: Item, optionKey: string): boolean {
    return control instanceof DateRangeFieldEntity && control.attrNames && control.attrNames.endDate === optionKey;
  }

  @action.bound
  private setDateBoundaries(item: DateRangeFieldEntity, value: number, isStartDate: boolean): void {
    if (isStartDate) {
      item.setStartDateBoundary(new Date(value * 1000));
    } else {
      item.setEndDateBoundary(new Date(value * 1000));
    }

    if (this.filterWidgetEntity.isFirstLoading) {
      item.returnInitialValue();
      this.filterWidgetEntity.setFirstLoading(false);
    }
  }

  @action.bound
  onReset(): void {
    this.filterWidgetEntity.resetAll();

    if (!this.filterWidgetEntity.isEditMode) {
      this.onWidgetDelete(this.filterWidgetEntity, false);
    }
  }

  @action.bound
  updateForm(): void {
    const { options } = this.filterWidgetEntity;

    if (options) {
      for (const [optionKey, values] of Object.entries(options)) {
        let isStartDate = false;

        const item = this.filterWidgetEntity.items.find((control) => {
          isStartDate = this.checkIsStartDate(control, optionKey);

          return (
            this.checkIsFilterMultiCombobox(control, optionKey) ||
            isStartDate ||
            this.checkIsEndDate(control, optionKey)
          );
        });

        if (item instanceof FilterMultiCombobox && Array.isArray(values)) {
          item.setRawOptions(values);

          if (this.filterWidgetEntity.isFirstLoading) {
            this.filterWidgetEntity.mapInitialState();
            this.filterWidgetEntity.setFirstLoading(false);
          }
        }

        if (item instanceof DateRangeFieldEntity && typeof values === 'number') {
          const isCorrectDate = values !== 0 && !Number.isNaN(new Date(values));
          if (isCorrectDate) {
            this.setDateBoundaries(item, values, isStartDate);
          }
        }
      }
    }
  }

  effect = () => {
    this.fetchOptions(true);

    const disposeDataSource = reaction(
      () => ({ values: this.dataSource }),
      () => {
        this.fetchOptions();
      }
    );

    return () => {
      disposeDataSource();
    };
  };
}
