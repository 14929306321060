import type {
  DirectionalDrillingWidgetEntity,
  TDirectionalDrillingSerializedInternalState,
} from 'src/pages/dashboard/features/directional-drilling-widget/DirectionalDrillingWidget.entity';
import type { TSerializedWidgetTemplate } from 'src/pages/dashboard/features/workspace/Workspace.store';

export function serializeDirectionalDrillingTemplate(
  widget: DirectionalDrillingWidgetEntity
): TSerializedWidgetTemplate {
  const internalState: TDirectionalDrillingSerializedInternalState = {
    wellId: widget.wellId,
    stateParams: {
      wellboreId: widget.stateParams.wellboreId,
      gravityToolParams: widget.stateParams.gravityToolParams,
      magneticToolParams: widget.stateParams.magneticToolParams,
      isPaused: widget.stateParams.isPaused,
      is360: widget.stateParams.is360,
    },
  };

  return {
    uuid: widget.uuid,
    type: widget.type,
    groupId: widget.groupId,
    size: {
      w: widget.size.w,
      h: widget.size.h,
    },
    position: {
      x: widget.position.x,
      y: widget.position.y,
    },
    internalState,
  };
}
