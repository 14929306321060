import type { TDirectionalDrillingSerializedInternalState } from './DirectionalDrillingWidget.entity';

export function parseDirectionalDrillingInternalState(state: object): TDirectionalDrillingSerializedInternalState {
  let wellId: number | null = null;
  let stateParams: object = {};

  if ('wellId' in state && typeof state.wellId === 'number') {
    wellId = state.wellId;
  }

  if ('stateParams' in state && typeof state.stateParams === 'object' && !!state.stateParams) {
    stateParams = state.stateParams;
  }

  return {
    wellId,
    stateParams,
  };
}
