import { VerticalCustom as WellIcon } from '@profgeosoft-ui/icons';
import { Select } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';

import { useService } from 'src/packages/di';

import type { BasicParametersStore } from '../BasicParameters.store';

import styles from './selects.module.scss';

interface Props {
  store: BasicParametersStore;
  itemView: {
    statusColor: Partial<Record<string, string>>;
    statusField: string;
  };
}

export const WellsSelect = observer(function WellsSelect({ store, itemView }: Props) {
  const { theme } = useService('theme');
  const { isWellsLoading, currentWell, wellsOptions } = store;

  return (
    <Select
      loading={isWellsLoading}
      disabled={isWellsLoading}
      className={styles.wellSelect}
      showSearch
      filterOption={(inputValue, option) => {
        if (option && (option.label || option.children)) {
          const lowerInput = inputValue.toLowerCase();

          return String(option.children).toLowerCase().includes(lowerInput);
        }
        return false;
      }}
      value={currentWell ? currentWell.name : null}
      onSelect={(value) => {
        store.setCurrentWell(value);
      }}
    >
      {wellsOptions.map((well) => {
        const color = well.status === 'COMPLETED' && theme === 'light' ? '#788CB9' : itemView.statusColor[well.status];
        return (
          <Select.Option key={well.value} value={well.value}>
            <WellIcon className={styles.drillingIcon} style={{ color: color ?? '#ffffff80' }} />
            {well.label}
          </Select.Option>
        );
      })}
    </Select>
  );
});
