import { ParametersPanelWidgetState } from '@go-widgets/well-logs-widget';
import { action, comparer, makeObservable, observable, reaction } from 'mobx';

import { WITH_WELLBORE_SORTING } from 'src/api/consts';
import { lagacyAgent } from 'src/api/legacyAgent';
import { wellLogsWidgetAgent } from 'src/api/wellLogsWidgetAgent';
import { requireService } from 'src/packages/di';
import { getNewAndDeletedFields } from 'src/packages/shared/utils/getNewAndDeletedElements';

import type { OperationalParametersWidgetEntity } from './OperationalParametersWidget.entity';
import type { GroupSelectStore } from '../group-select/GroupSelect.store';
import type { ParametersInitParams } from '@go-widgets/well-logs-widget';
import type { IReactionDisposer, ObservableMap } from 'mobx';
import type { TGroup } from 'src/entities/tab/TabEntity';
import type { IWidgetStore } from 'src/entities/widget/IWidgetStore';
import type { WidgetEntity } from 'src/entities/widget/WidgetEntity';

import { setWellIdInGroup } from '../../utils/setWellIdInGroup';
import { WellListWidgetEntity } from '../well-list-widget/WellListWidget.entity';

import { getCorrectLocale } from './OperationalParametersWidget.utils';

export class OperationalParametersWidgetStore implements IWidgetStore {
  private readonly operationalParametersEntity: OperationalParametersWidgetEntity;
  groupSelectStore: GroupSelectStore | null = null;
  private readonly initParams: ParametersInitParams;

  @observable isLoading: boolean = false;
  @observable isReady: boolean = false;
  @observable hasError: boolean = false;
  @observable disposers: ObservableMap<WidgetEntity, IReactionDisposer> = observable.map();
  @observable isGroupSelectOpen: boolean = false;

  constructor(
    operationalParametersEntity: OperationalParametersWidgetEntity,
    groupSelectStore?: GroupSelectStore,
    private readonly theme = requireService('theme'),
    private readonly otherSettings = requireService('otherSettings'),
    private readonly stompCachePublisherService = requireService('stompCachePublisherService'),
    private readonly notifications = requireService('notifications'),
    private readonly language = requireService('language'),
    private readonly classOfUnitsService = requireService('classOfUnitsService')
  ) {
    const { wellIndexType } = operationalParametersEntity;

    this.operationalParametersEntity = operationalParametersEntity;
    this.isGroupSelectOpen = operationalParametersEntity.isGroupsDefaultOpened;

    if (groupSelectStore) {
      this.groupSelectStore = groupSelectStore;
    }

    this.initParams = {
      index: wellIndexType,
      legacyAxiosInstance: lagacyAgent,
      stompPublisher: this.stompCachePublisherService.stompPublisher,
      axiosInstance: wellLogsWidgetAgent,
      withWellboreSorting: WITH_WELLBORE_SORTING,
      classOfUnitsService: this.classOfUnitsService,
    };

    makeObservable(this);
  }

  @action.bound
  setIsGroupSelectOpen(isOpen: boolean): void {
    this.isGroupSelectOpen = isOpen;
  }

  @action.bound
  exitFullScreen() {
    this.operationalParametersEntity.setFullscreen(false);
  }

  @action.bound
  setGroupForWellLogs(group: TGroup | null): void {
    this.operationalParametersEntity.setGroup(group ? group.id : null);
  }

  @action.bound
  setWellId(well: number | null): void {
    this.operationalParametersEntity.setWellId(well);
  }

  @action.bound
  setEditOpen() {
    this.operationalParametersEntity.state?.enterEditMode();
  }

  @action.bound
  setGroupSelectStore(groupSelectStore: GroupSelectStore) {
    this.groupSelectStore = groupSelectStore;
  }

  private mapNewWidgets(widgets: WidgetEntity[]): void {
    for (const widget of widgets) {
      if (!(widget instanceof WellListWidgetEntity) || this.disposers.has(widget)) {
        continue;
      }

      const disposeWellListWidget = reaction(
        () => widget.selectedWellID,
        (wellId) => {
          this.operationalParametersEntity.setWellId(wellId);
        }
      );

      this.disposers.set(widget, disposeWellListWidget);
    }
  }

  @action.bound
  private setReady(value: boolean): void {
    this.isReady = value;
  }

  @action.bound
  private setInActiveTab(value: boolean) {
    this.operationalParametersEntity.setInActiveTab(value);
  }

  private mapDeletedWidgets(widgets: WidgetEntity[]): void {
    for (const widget of widgets) {
      if (this.disposers.has(widget)) {
        const disposer = this.disposers.get(widget);

        disposer?.();

        this.disposers.delete(widget);
      }
    }
  }

  private getNewState(wellId: number): ParametersPanelWidgetState {
    return new ParametersPanelWidgetState(
      this.initParams,
      {
        // TODO: remove hardcode
        wellId,
        width: 500,
        height: 500,
        locale: getCorrectLocale(this.language.language),
        theme: this.theme.theme,
        active: this.operationalParametersEntity.inActiveTab,
        isAltNames: this.otherSettings.isAltNames,
      },
      this.operationalParametersEntity.stateParams,
      {
        onInitializedSuccess: () => {
          this.setReady(true);
        },
        onInitializationFailed: (_, error) => {
          this.setReady(false);

          console.error(error);
          this.notifications.showErrorMessageT('errors:failedToInitWidget');
        },
        onInternalStateChanged: (state) => {
          this.operationalParametersEntity.setStateParams(state);
        },
        onAlertRegistered: (state) => {
          this.operationalParametersEntity.setHasAlarm(state);
        },
      }
    );
  }

  initWidgetByWellId(wellId: number) {
    const newState = this.getNewState(wellId);
    this.operationalParametersEntity.setState(newState);
  }

  effect = () => {
    this.setInActiveTab(true);

    const disposeGroupId = reaction(
      () => this.operationalParametersEntity.groupId,
      (groupId) => {
        if (groupId === null || this.groupSelectStore == null) return;

        const widgets = this.groupSelectStore.groupManager.getWidgetsByGroupId(groupId);
        setWellIdInGroup(widgets, this.operationalParametersEntity);
      }
    );

    const dispose = reaction(
      () => {
        if (this.groupSelectStore) {
          return this.groupSelectStore.groupManager
            .getWidgetsByGroupId(this.operationalParametersEntity.groupId)
            .slice();
        }
        return [];
      },
      (widgets, prevState) => {
        const prevWidgets = prevState ?? [];

        const { newElements: newWidgets, deletedElements: deletedWidgets } = getNewAndDeletedFields<WidgetEntity>(
          widgets,
          prevWidgets
        );

        this.mapNewWidgets(newWidgets);
        this.mapDeletedWidgets(deletedWidgets);
      },
      { fireImmediately: true, equals: comparer.shallow }
    );

    const disposeWellId = reaction(
      () => this.operationalParametersEntity.wellId,
      (wellId, prevWellId) => {
        if (wellId && !this.operationalParametersEntity.state) {
          const newState = this.getNewState(wellId);

          this.operationalParametersEntity.setState(newState);
        } else if (wellId && this.operationalParametersEntity.state && typeof prevWellId !== 'undefined') {
          this.operationalParametersEntity.state.setAppParams({ wellId });
        } else if (!wellId && this.operationalParametersEntity.state) {
          this.operationalParametersEntity.state.destroy();
          this.operationalParametersEntity.setState(null);
        }
      },
      { fireImmediately: true }
    );

    const disposeStateParams = reaction(
      () => ({
        theme: this.theme.theme,
        language: this.language.language,
        isAltNames: this.otherSettings.isAltNames,
      }),
      ({ theme, language, isAltNames }) => {
        this.operationalParametersEntity.state?.setAppParams({
          theme,
          locale: getCorrectLocale(language),
          isAltNames,
        });
      },
      {
        fireImmediately: true,
      }
    );

    return () => {
      this.setInActiveTab(false);
      dispose();
      disposeGroupId();
      disposeWellId();
      disposeStateParams();
    };
  };
}
