import type { TRenderWidget, TWidgetRendererOptions } from './WidgetRendererService';
import type { DirectionalDrillingWidgetEntity } from '../../directional-drilling-widget/DirectionalDrillingWidget.entity';

import { DirectionalDrillingWidgetWrapper } from '../../directional-drilling-widget/DirectionalDrillingWidget';

export function renderDirectionalDrillingWidget(
  widget: DirectionalDrillingWidgetEntity,
  options: TWidgetRendererOptions
): TRenderWidget {
  return ({ onCreateDirectionalDrillingWidget }) => (
    <DirectionalDrillingWidgetWrapper
      entity={widget}
      options={options}
      onCreateDirectionalDrillingWidget={onCreateDirectionalDrillingWidget}
    />
  );
}
