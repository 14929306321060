import { createNotification } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useStore } from 'src/app/root-store';
import { Switchers } from 'src/components/sidebar/switchers/Switchers';
import { checkIsNode } from 'src/packages/shared/utils/checkIsNode';

import { Logo } from './logo/Logo';
import { Navigation } from './navigation/Navigation';
import { Support } from './support/Support';
import { UserSection } from './user-section/UserSection';
import { Versions } from './versions-content/Versions';

import styles from './Sidebar.module.scss';

export const Sidebar = observer(function Sidebar() {
  const { sidebar, auth, versions } = useStore();
  const isOpened = sidebar.isOpened;
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!checkIsNode(event.target)) return;
      if (ref.current!.contains(event.target)) return;
      sidebar.close();
    };

    document.body.addEventListener('pointerdown', handleClickOutside);

    return () => {
      document.body.removeEventListener('pointerdown', handleClickOutside);
    };
  }, [sidebar]);

  return (
    <>
      <div ref={ref} className={clsx(styles.wrapper, !isOpened && styles.wrapper__closed)}>
        <div className={styles.block}>
          <Logo
            handleClick={() => {
              sidebar.close();
              if (!versions.isOpened) {
                createNotification({
                  title: t('modals:Versions.info'),
                  text: (
                    <Versions
                      frontend={versions.frontendV}
                      backend={versions.backendV}
                      orchestrator={versions.orchestratorV}
                      cache={versions.cacheV}
                    />
                  ),
                  type: 'info',
                  autoClose: false,
                  closeButton: true,
                  closeOnClick: false,
                  onClose: () => {
                    versions.setIsOpened(false);
                  },
                  onOpen: () => {
                    versions.setIsOpened(true);
                  },
                });
              }
            }}
            isOpened={isOpened}
          />
          <Navigation isOpened={isOpened} onCloseSidebar={sidebar.close} />
        </div>
        <div className={styles.block}>
          <Support isOpened={isOpened} onCloseSidebar={sidebar.close} />
          <Switchers />
          <UserSection isOpened={isOpened} userInfo={auth.userInfo} onLogout={auth.logout} />
        </div>
        <button className={clsx(styles.closeButton, isOpened && styles.closeButton__opened)} onClick={sidebar.close} />
      </div>
    </>
  );
});
