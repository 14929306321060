import { action, makeObservable, observable } from 'mobx';

import type { WellIndexType } from '@go-widgets/well-logs-widget';
import type { TabEntity } from 'src/entities/tab/TabEntity';

import { widgetsGroups } from './widgetsGroups';

export class NewContentWidgetModalStore {
  private readonly tab: TabEntity;

  @observable chosenGroupType: TWidgetTypeGroup | null = null;

  constructor(actualTab: TabEntity) {
    this.tab = actualTab;

    makeObservable(this);
  }

  get widgetsGroups(): (TWidgetTypeGroup | TWidgetType)[] {
    return widgetsGroups;
  }

  @action.bound
  setChosenGroupType(group: TWidgetTypeGroup | null): void {
    this.chosenGroupType = group;
  }
}

export type TWidgetTypeGroup = {
  type: string;
  name: string;
  description: string;
  widgets: TWidgetType[];
  iconsMap?: [TIconTuple, TIconTuple];
};

export type TWidgetType = {
  name: string;
  type: string;
  description: string;
  wellIndexType?: WellIndexType;
  iconsMap?: [TIconTuple, TIconTuple];
};

export type TIconTuple = [TIcon?, TIcon?, TIcon?];

export type TIcon =
  | 'clock'
  | 'graph'
  | 'detail'
  | 'analyze'
  | 'broadcast'
  | 'depth'
  | 'vertArrow'
  | 'empty'
  | 'operational'
  | 'directional'
  | 'video';
