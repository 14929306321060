import { DirectionalDrillingWidgetEntity } from 'src/pages/dashboard/features/directional-drilling-widget/DirectionalDrillingWidget.entity';
import { parseDirectionalDrillingInternalState } from 'src/pages/dashboard/features/directional-drilling-widget/parseDirectionalDrillingWidget';

import type { TSerializedWidget } from 'src/entities/workspace/types';

export function mapDirectionalDrillingWidget(
  widget: TSerializedWidget,
  index: number
): DirectionalDrillingWidgetEntity {
  const internalState = parseDirectionalDrillingInternalState(widget.internalState);
  const widgetEntity = new DirectionalDrillingWidgetEntity(
    {
      wellId: internalState.wellId,
      stateParams: internalState.stateParams,
      type: 'directional-drilling-widget',
    },
    {
      uuid: widget.uuid,
      isPinned: !!widget.isPinned,
      zIndex: index + 1,
      isFullscreen: widget.isFullscreen ?? false,
      position: {
        x: widget?.position?.x ?? 0,
        y: widget?.position?.y ?? 0,
      },
      size: {
        w: widget.size?.w ?? 59,
        h: widget.size?.h ?? 316,
      },
      groupId: widget.groupId,
      isGroupsDefaultOpened: false,
    }
  );

  return widgetEntity;
}
