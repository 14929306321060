export interface IWell {
  id: number;
  name: string;
  status: WellStatuses;
  timeZone: string;
  goStreamWellIds: number[];
}

export interface IWellbore {
  id: number;
  name: string;
  maxTimeLogDepth: number;
  maxTimeLogTime: number;
  wellId: number;
  numLogsTime: number;
  numLogsDepth: number;
}

export interface ICurve {
  id: number;
  mnemonic: string;
  typeExt: string;
  goStreamId: number;
  unit: {
    id: number;
    abbreviation: string;
  };
  parameter: {
    id: number;
    name: string;
    displayMnemonic: string;
    generalName?: string;
    classOfUnitId: number;
  };
}

export interface IWellLog {
  id: number;
  logName?: {
    id: number;
    name: string;
  };
  index: 'LOG_DEPTH' | 'LOG_TIME';
  curves: Array<ICurve>;
  runNumber: number;
  supplier?: {
    id: number;
    name: string;
  };
  service?: {
    id: number;
    name: string;
  };
}

export enum LogsIndex {
  TIME = 'LOG_TIME',
  DEPTH = 'LOG_DEPTH',
}

export enum BaseParameterTypes {
  HOOKLOAD = 'HOOKLOAD',
  STANDPIPE_PRESSURE = 'STANDPIPE_PRESSURE',
  DEPTH_HOLE = 'DEPTH_HOLE',
  ROTARY_TORQUE = 'ROTARY_TORQUE',
  BLOCK_POSITION = 'BLOCK_POSITION',
  DEPTH_BIT = 'DEPTH_BIT',
  MUD_FLOW_IN = 'MUD_FLOW_IN',
  RATE_OF_PENETRATION = 'RATE_OF_PENETRATION',
  WEIGHT_ON_BIT = 'WEIGHT_ON_BIT',
  ROTARY_SPEED = 'ROTARY_SPEED',
  AUTO_RECOGNIZED_EVENTS = 'AUTO_RECOGNIZED_EVENTS',
  AUTO_RECOGNIZED_OPERATIONS = 'AUTO_RECOGNIZED_OPERATIONS',
  MAGNETIC_TOOLFACE = 'MAGNETIC_TOOLFACE',
  GRAVITY_TOOLFACE = 'GRAVITY_TOOLFACE',
}

export interface IBaseParameter {
  wellboreId: number;
  parameter: BaseParameterTypes;
  logType: LogsIndex;
  curveId?: number;
}

export enum WellStatuses {
  ACTIVE_GREEN = 'ACTIVE_GREEN',
  ACTIVE_YELLOW = 'ACTIVE_YELLOW',
  ACTIVE_RED = 'ACTIVE_RED',
  COMPLETED = 'COMPLETED',
  BAD = 'BAD',
}
