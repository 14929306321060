import { parseVideobroadcastInternalState } from 'src/pages/dashboard/features/videobroadcast-widget/parseVideobroadcastWidget';
import { VideobroadcastWidgetEntity } from 'src/pages/dashboard/features/videobroadcast-widget/VideobroadcastWidget.entity';

import type { TSerializedWidget } from '../../entities/workspace/types';

export function mapVideobroadcastWidget(widget: TSerializedWidget, index: number): VideobroadcastWidgetEntity {
  const internalState = parseVideobroadcastInternalState(widget.internalState);
  const widgetEntity = new VideobroadcastWidgetEntity(
    {
      wellId: internalState.wellId,
      stateParams: internalState.stateParams,
      type: 'videobroadcast-widget',
    },
    {
      uuid: widget.uuid,
      isPinned: !!widget.isPinned,
      zIndex: index + 1,
      isFullscreen: widget.isFullscreen ?? false,
      position: {
        x: widget?.position?.x ?? 0,
        y: widget?.position?.y ?? 0,
      },
      size: {
        w: widget.size?.w ?? 59,
        h: widget.size?.h ?? 316,
      },
      groupId: widget.groupId,
      isGroupsDefaultOpened: false,
    }
  );

  return widgetEntity;
}
