import { action, computed, makeObservable, observable } from 'mobx';

import { WidgetEntity } from 'src/entities/widget/WidgetEntity';
import { requireService } from 'src/packages/di';
import { isNumberArray } from 'src/packages/shared/utils/is-number-array';
import { isStringArray } from 'src/packages/shared/utils/is-string-array';
import { mapItems } from 'src/serializers/controls/mapper';

import type { TFilterOptions } from './api/FilterWidget.api';
import type { TFilterState } from './FilterWidget.utils';
import type { TFilterDataSource } from './WellsFilterDataSource';
import type { Item } from '../controls/abstract-control-entities';
import type { TWellsFilterView } from 'src/api-types/wells.types';
import type { SizeBoundaries, TWidgetOptions } from 'src/entities/widget/WidgetEntity';

import { DateRangeFieldEntity } from '../controls/entities/DateRangeField.entity';
import { FilterMultiCombobox } from '../controls/entities/FilterMultiCombobox.entity';

import { serializeItemsToData, serializeItemsToInitialData } from './FilterWidget.utils';

export type TWellsFilterInternalState = {
  isEditMode: boolean;
  state: TFilterState | null;
  initialState: TFilterState | null;
};

interface FilterWidgetOptions {
  type: string;
  isEditMode: boolean;
  state: TFilterState | null;
  initialState: TFilterState | null;
}

export class FilterWidgetEntity extends WidgetEntity {
  readonly view: TWellsFilterView;
  readonly sizeBoundaries: SizeBoundaries = {
    minInPixels: {
      w: 280,
      h: 464,
    },
    maxInPixels: {
      w: 560,
    },
    min: {
      w: 37,
      h: 50,
    },
    max: {
      w: 93,
      h: 100,
    },
  };

  @observable isEditMode: boolean;
  @observable state: TFilterState | null;
  @observable initialState: TFilterState | null;
  @observable isFirstLoading: boolean = true;
  @observable hasChanges: boolean = false;
  @observable items: Item[] = [];
  @observable options: TFilterOptions | null = null;
  @observable type: string;

  constructor(
    { type, isEditMode, state, initialState }: FilterWidgetOptions,
    widgetOptions: TWidgetOptions,
    private readonly preloadService = requireService('preloadService')
  ) {
    super(widgetOptions);

    const view = this.preloadService.getPreloadedData<TWellsFilterView>('filter-control');
    const items = mapItems(view.fields);

    this.isEditMode = isEditMode;
    this.state = state;
    this.initialState = initialState;
    this.items = items;
    this.view = view;
    this.type = type;

    makeObservable(this);
  }

  @action.bound
  setFullscreen(value: boolean): void {
    this.isFullscreen = value;
  }

  getNameT(): string {
    return 'wellsFilter:title';
  }

  @action.bound
  setState(state: TFilterState | null): void {
    this.state = state;
  }

  override setGroup(group: string | null): void {
    this.groupId = group;

    if (group === null) {
      this.resetAll();
    }
  }

  get isControlWidget(): boolean {
    return true;
  }

  get title(): string {
    const i18 = requireService('i18');

    return i18.t('wellsFilter:title');
  }

  @computed
  get data(): TFilterDataSource {
    return serializeItemsToData(this.items);
  }

  @computed
  get initialData(): TFilterDataSource {
    return serializeItemsToInitialData(this.items);
  }

  @action.bound
  mapInitialState(): void {
    const { initialState } = this;

    const filterState = initialState;

    if (Array.isArray(filterState)) {
      for (const prop of filterState) {
        const name = prop.name;
        const value = prop.value;

        if (typeof name === 'string') {
          for (const item of this.items) {
            if (item instanceof FilterMultiCombobox) {
              if (item.attrName === name && (isStringArray(value) || isNumberArray(value))) {
                item.setInitialValue(value);
                item.setValue(value);
              }
            }

            if (item instanceof DateRangeFieldEntity) {
              if (item.attrNames.startDate === name && typeof value === 'number') {
                const dates = {
                  startDate: new Date(value * 1000),
                  endDate: item.initialValue.endDate,
                };

                item.setInitialValue(dates);
                item.setValue(dates);
              }

              if (item.attrNames.endDate === name && typeof value === 'number') {
                const dates = {
                  startDate: item.initialValue.startDate,
                  endDate: new Date(value * 1000),
                };
                item.setInitialValue(dates);
                item.setValue(dates);
              }
            }
          }
        }
      }
    }
  }

  @action.bound
  setInitialState(state: TFilterState | null): void {
    this.initialState = state;
  }

  @action.bound
  setFirstLoading(value: boolean): void {
    this.isFirstLoading = value;
  }

  @action.bound
  setEditMode(value: boolean): void {
    this.isEditMode = value;
  }

  @action.bound
  setOptions(options: TFilterOptions): void {
    this.options = options;
  }

  @action.bound
  reset(): void {
    this.items.forEach((field) => field.clearItem());
  }

  @action.bound
  resetAll(): void {
    this.items.forEach((field) => {
      field.clearItem();
      field.clearInitialValue();
    });
  }

  @action.bound
  cancelChanges(): void {
    this.items.forEach((item) => item.returnInitialValue());
  }

  @action.bound
  acceptChanges(): void {
    for (const item of this.items) {
      if (item instanceof FilterMultiCombobox) {
        item.setInitialValue(item.value);
      }

      if (item instanceof DateRangeFieldEntity) {
        item.setInitialValue({
          startDate: item.startDate,
          endDate: item.endDate,
        });
      }
    }
  }
}
