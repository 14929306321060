import { DirectionalDrillingWidgetEntity } from 'src/pages/dashboard/features/directional-drilling-widget/DirectionalDrillingWidget.entity';
import { parseDirectionalDrillingInternalState } from 'src/pages/dashboard/features/directional-drilling-widget/parseDirectionalDrillingWidget';

import type { TSerializedWidgetTemplate } from 'src/pages/dashboard/features/workspace/Workspace.store';

export function mapDirectionalDrillingTemplate(
  template: TSerializedWidgetTemplate,
  index: number
): DirectionalDrillingWidgetEntity {
  const { size, position } = template;

  const internalState = parseDirectionalDrillingInternalState(template.internalState ?? {});

  const directionalDrillingWidget = new DirectionalDrillingWidgetEntity(
    {
      wellId: internalState.wellId,
      stateParams: internalState.stateParams ?? {},
      type: 'directional-drilling-widget',
    },
    {
      groupId: template.groupId || null,
      isGroupsDefaultOpened: false,
      uuid: template.uuid,
      isPinned: false,
      isFullscreen: false,
      zIndex: index + 1,
      size: {
        w: size.w,
        h: size.h,
      },
      position: {
        x: position.x,
        y: position.y,
      },
    }
  );

  return directionalDrillingWidget;
}
