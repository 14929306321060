import { parseDirectionalDrillingInternalState } from 'src/pages/dashboard/features/directional-drilling-widget/parseDirectionalDrillingWidget';

import type { TSerializedWidget } from 'src/entities/workspace/types';
import type { DirectionalDrillingWidgetEntity } from 'src/pages/dashboard/features/directional-drilling-widget/DirectionalDrillingWidget.entity';

import { baseWidgetApplyChanges } from './baseWidgetApplyChanges';

export function directionalDrillingApplyChanges(
  widget: DirectionalDrillingWidgetEntity,
  changes: TSerializedWidget,
  index: number
) {
  baseWidgetApplyChanges(widget, changes, index);

  const internalState = parseDirectionalDrillingInternalState(changes.internalState);

  widget.setWellId(internalState.wellId);
  widget.setStateParams(internalState.stateParams);
}
