import { MenuNewLightCustom as OpenSidebarIcon } from '@profgeosoft-ui/icons';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { useStore } from 'src/app/root-store';

import styles from './SidebarButton.module.scss';

export const SidebarButton = observer(function SidebarButton() {
  const { sidebar } = useStore();

  return (
    <button className={clsx(styles.openButton, sidebar.isOpened && styles.openButton__active)} onClick={sidebar.open}>
      <OpenSidebarIcon />
    </button>
  );
});
