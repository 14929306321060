import { action, makeObservable, observable } from 'mobx';

import type { SizeBoundaries, TWidgetOptions } from '../../../../entities/widget/WidgetEntity';
import type { VideoBroadcastStateParams, VideoBroadcastWidgetState, WellIndexType } from '@go-widgets/well-logs-widget';

import { BaseWidgetEntityWithWellId } from '../../../../entities/widget/BaseWidgetEntityWithWellId';
import { requireService } from '../../../../packages/di';

interface VideobroadcastOptions {
  type: string;
  stateParams: Partial<VideoBroadcastStateParams>;
  wellId: number | null;
}

export type TVideobroadcastSerializedInternalState = {
  stateParams: Partial<VideoBroadcastStateParams>;
  wellId: number | null;
};

export class VideobroadcastWidgetEntity extends BaseWidgetEntityWithWellId {
  readonly sizeBoundaries: SizeBoundaries = {
    minInPixels: {
      w: 520,
      h: 400,
    },
    maxInPixels: null,
    min: {
      w: 53,
      h: 40,
    },
    max: {
      w: 256,
      h: 100,
    },
  };

  @observable state: VideoBroadcastWidgetState | null = null;
  @observable type: string;
  @observable wellId: number | null;
  @observable stateParams: Partial<VideoBroadcastStateParams>;
  @observable wellIndexType: WellIndexType;
  @observable inActiveTab: boolean = false;

  constructor(
    { type, stateParams, wellId }: VideobroadcastOptions,
    widgetOptions: TWidgetOptions,
    private readonly i18 = requireService('i18')
  ) {
    super(widgetOptions);

    this.type = type;
    this.wellId = wellId;
    this.stateParams = stateParams;
    this.wellIndexType = 'LOG_TIME';

    makeObservable(this);
  }

  getNameT(): string {
    return 'widgets:VideoBroadcast.title';
  }

  @action.bound
  setWellId(wellId: number | null): void {
    this.wellId = wellId;
  }

  @action.bound
  setStateParams(stateParams: Partial<VideoBroadcastStateParams>): void {
    this.stateParams = stateParams;
  }

  get isControlWidget(): boolean {
    return false;
  }

  get title(): string {
    return this.i18.t(this.getNameT());
  }

  @action.bound
  setState(state: VideoBroadcastWidgetState | null): void {
    this.state = state;
  }

  @action.bound
  setFullscreen(value: boolean): void {
    this.isFullscreen = value;
  }

  @action.bound
  setInActiveTab(value: boolean): void {
    this.inActiveTab = value;
    this.state?.setAppParams({
      active: this.inActiveTab,
    });
  }

  override destroy() {
    this.state?.destroy();
  }
}
