import { OperationsLogStompPublisher } from '@go-widgets/well-logs-widget';

import { API_WEBSOCKET_OPERATIONS_PATH } from 'src/api/consts';

export interface IOperationsStompPublisherService {
  stompPublisher: OperationsLogStompPublisher;
}

export class OperationsLogPublisherService implements IOperationsStompPublisherService {
  stompPublisher = new OperationsLogStompPublisher(API_WEBSOCKET_OPERATIONS_PATH);

  init(): void {
    this.stompPublisher.init();
  }
}
