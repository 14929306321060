import { observer } from 'mobx-react-lite';
import { useRef } from 'react';

import { useService } from 'src/packages/di';
import { useOutsideClick } from 'src/packages/shared/hooks/use-outside-click';

import type { WellListWidgetStore } from '../well-list-widget/WellListWidget.store';
import type { WorkspaceStore } from '../workspace/Workspace.store';

import { WellListWidgetEntity } from '../well-list-widget/WellListWidget.entity';
import { WellListWidgetBody } from '../well-list-widget/WellListWidgetBody';

import styles from './WellListWidgetAsPopup.module.scss';

type Props = {
  workspaceStore: WorkspaceStore;
};

export const WellListWidgetAsPopup = observer(function WellListWidgetAsPopup({ workspaceStore }: Props) {
  const { actualTab } = workspaceStore;
  const widgetsStoreService = useService('widgetStoreService');
  const ref = useRef<HTMLDivElement | null>(null);

  useOutsideClick(ref, () => workspaceStore.setIsWellListInFSModeOpen(false));

  if (!actualTab) {
    return null;
  }

  const { fullscreenWidget, groupManager } = actualTab;

  if (!fullscreenWidget) {
    return null;
  }

  const wellListWidgetEntity = groupManager
    .getWidgetsByGroupId(fullscreenWidget.groupId)
    ?.find((w): w is WellListWidgetEntity => w instanceof WellListWidgetEntity);

  if (!wellListWidgetEntity) {
    return null;
  }

  const wellListWidgetStore = widgetsStoreService.getStore<WellListWidgetEntity, WellListWidgetStore>(
    wellListWidgetEntity
  );

  if (!wellListWidgetStore) {
    return null;
  }

  return (
    <div ref={ref} className={styles.popupWrapper}>
      <WellListWidgetBody
        store={wellListWidgetStore}
        wellListEntity={wellListWidgetEntity}
        onWellSelect={() => workspaceStore.setIsWellListInFSModeOpen(false)}
      />
    </div>
  );
});
