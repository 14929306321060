import { makeObservable, observable } from 'mobx';

import { requireService } from 'src/packages/di';

import type { Stack } from '../../../../packages/data/structures/Stack';
import type { TWellListView } from 'src/api-types/wells.types';

import { BaseSettingsScreen } from '../screen/BaseSettingsScreen';

export class BasicParametersEntity extends BaseSettingsScreen {
  readonly type = 'basic-parameters';
  @observable view: TWellListView;

  constructor(stack: Stack<BaseSettingsScreen>, private readonly preloadService = requireService('preloadService')) {
    super(stack);

    const view = this.preloadService.getPreloadedData<TWellListView>('well-list-control');
    this.view = view;

    makeObservable(this);
  }
}
