import {
  DoneRoundLight as CheckMarkIcon,
  CloseRoundLight as CrossIcon,
  EditLight as EditIcon,
  RegroupLight as SwapIcon,
} from '@profgeosoft-ui/icons';
import { Button, Loader } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { requireService } from 'src/packages/di';

import type { FilterWidgetEntity } from './FilterWidget.entity';
import type { TWidgetRendererOptions } from '../workspace/services/WidgetRendererService';

import { WidgetHeader } from '../widget-header/WidgetHeader';
import { PinWidgetButton } from '../workbench/pin-widget-button/PinWidgetButton';

import { FilterControlsList } from './components/filter-controls-list/FilterControlsList';
import { FilterWidgetStore } from './FilterWidget.store';

import styles from './FilterWidget.module.scss';

type WrapperProps = {
  filterWidgetEntity: FilterWidgetEntity;
  options: TWidgetRendererOptions;
};

type Props = WrapperProps & {
  store: FilterWidgetStore;
};

export const FilterWidgetWrapper = observer(function FilterWidgetWrapper({
  filterWidgetEntity,
  options,
}: WrapperProps) {
  const [store, setStore] = useState<FilterWidgetStore | null>(null);

  const storeService = requireService('widgetStoreService');

  useEffect(() => {
    if (store) return;

    const _store = storeService.getStore<FilterWidgetEntity, FilterWidgetStore>(filterWidgetEntity);

    if (_store) {
      setStore(_store);
    } else {
      const newStore = new FilterWidgetStore(
        filterWidgetEntity,
        options.groupSelectStore.groupManager,
        options.onWidgetDelete
      );

      storeService.setStore<FilterWidgetEntity, FilterWidgetStore>(filterWidgetEntity, newStore);

      setStore(newStore);
    }
  }, [storeService, store, filterWidgetEntity, options.groupSelectStore.groupManager, options.onWidgetDelete]);

  if (!store) return null;

  return <FilterWidget filterWidgetEntity={filterWidgetEntity} options={options} store={store} />;
});

export const FilterWidget = observer(function FilterWidget({ filterWidgetEntity, options, store }: Props) {
  const handlePinWidget = () => options.widgetsDisplayingPriorityManager.togglePinWidget(filterWidgetEntity);

  const { t } = useTranslation();

  const { isLoading, isResetButtonDisabled, onReset, setGroupForWellsFilter, effect } = store;
  const { isEditMode, setEditMode, items, cancelChanges, acceptChanges } = filterWidgetEntity;

  useEffect(effect, [effect]);

  return (
    <>
      <WidgetHeader
        entity={filterWidgetEntity}
        onWidgetChangeGroup={setGroupForWellsFilter}
        groupSelectStore={options.groupSelectStore}
        widgetName={filterWidgetEntity.title}
        onWidgetDelete={() => options.onWidgetDelete(filterWidgetEntity)}
        renderAdditionalButtons={() => (
          <>
            {filterWidgetEntity.isControlWidget && (
              <PinWidgetButton isPinned={filterWidgetEntity.isPinned} onClick={handlePinWidget} />
            )}
          </>
        )}
      />
      <div className={styles.wrapper}>
        {isLoading && <Loader className={styles.loader} />}
        <FilterControlsList items={items} isLoading={isLoading} isEditMode={isEditMode} />
        <div className={styles.buttons}>
          {!isEditMode && (
            <Button
              variant="success"
              className={styles.button}
              icon={<EditIcon className={styles.icon} />}
              onClick={() => setEditMode(true)}
            >
              {t('wellsFilter:Buttons.edit')}
            </Button>
          )}

          {isEditMode && (
            <div className={styles.editModeButtons}>
              <Button
                variant="danger"
                className={styles.button}
                icon={<CrossIcon className={styles.icon} />}
                onClick={cancelChanges}
              >
                {t('common:Buttons.cancel')}
              </Button>
              <Button
                variant="success"
                className={styles.button}
                icon={<CheckMarkIcon className={styles.icon} />}
                onClick={acceptChanges}
              >
                {t('common:Buttons.accept')}
              </Button>
            </div>
          )}

          <Button
            variant="primary"
            className={styles.button}
            icon={<SwapIcon className={styles.icon} />}
            onClick={onReset}
            disabled={isResetButtonDisabled}
          >
            {t('wellsFilter:Buttons.reset')}
          </Button>
        </div>
      </div>
    </>
  );
});
