import type { TVideobroadcastSerializedInternalState } from './VideobroadcastWidget.entity';

export function parseVideobroadcastInternalState(state: object): TVideobroadcastSerializedInternalState {
  let wellId: number | null = null;
  let stateParams: object = {}; //todo

  if ('wellId' in state && typeof state.wellId === 'number') {
    wellId = state.wellId;
  }

  if ('stateParams' in state && typeof state.stateParams === 'object' && !!state.stateParams) {
    stateParams = state.stateParams;
  }

  return {
    wellId,
    stateParams,
  };
}
