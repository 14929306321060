import { ExpandDownLight as ArrowIcon, DotsEditCustom as DotsIcon } from '@profgeosoft-ui/icons';
import { Button, Tooltip } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { WELL_DETAILS_AVAILABLE } from 'src/api/consts';

import type { WellIndexType, WidgetStateParams } from '@go-widgets/well-logs-widget';
import type { MouseEvent } from 'react';
import type { TCreateWidgetOptions } from 'src/entities/tab/TabEntity';

import styles from './ContextMenuButton.module.scss';

type Props = {
  id: number;
  onCreateWellLogsWidget?(
    wellIndexType: WellIndexType,
    wellId: number | null,
    stateParams: Partial<WidgetStateParams> | null,
    options?: TCreateWidgetOptions
  ): void;
};

export const ContextMenuButton = observer(function ContextMenuButton({ id, onCreateWellLogsWidget }: Props) {
  const [isCollapsed, setCollapsed] = useState(true);
  const [isOpen, setOpen] = useState(false);

  const { t } = useTranslation('wellList');

  const onCreateWelLogsByTime = (e: MouseEvent) => {
    e.stopPropagation();
    setOpen(false);
    onCreateWellLogsWidget?.('LOG_TIME', id, null);
  };

  const onCreateWelLogsByDepth = (e: MouseEvent) => {
    e.stopPropagation();
    setOpen(false);
    onCreateWellLogsWidget?.('LOG_DEPTH', id, null);
  };

  const onCollapseClick = (e: MouseEvent) => {
    e.stopPropagation();
    setCollapsed((p) => !p);
  };

  // TODO: Добавить условие отсутсвия колбэка для создания деталей скважины, когда он появится
  if (!onCreateWellLogsWidget) {
    return null;
  }

  const content = (
    <div className={styles.wrapper}>
      {!!onCreateWellLogsWidget && (
        <>
          <Button
            variant="flat"
            className={clsx(styles.button, styles.collapseButton)}
            icon={<ArrowIcon className={clsx(styles.arrowIcon, isCollapsed && styles.arrowIconUp)} />}
            onClick={(e) => onCollapseClick(e)}
          >
            {t('contextMenu.sensorsParams')}
          </Button>
          {!isCollapsed && (
            <div className={styles.collapseContent}>
              <button
                type="button"
                className={clsx(styles.collapseContentButton, styles.button)}
                onClick={onCreateWelLogsByTime}
              >
                {t('contextMenu.commonByTime')}
              </button>
              <button
                type="button"
                className={clsx(styles.collapseContentButton, styles.button)}
                onClick={onCreateWelLogsByDepth}
              >
                {t('contextMenu.commonByDepth')}
              </button>
            </div>
          )}
        </>
      )}
      {WELL_DETAILS_AVAILABLE && (
        <button type="button" className={styles.button} onClick={(e) => e.stopPropagation()}>
          {t('contextMenu.wellData')}
        </button>
      )}
    </div>
  );

  return (
    <Tooltip
      placement="bottom-end"
      withArrow={false}
      title={content}
      className={styles.popover}
      onOpenChange={setOpen}
      open={isOpen}
      trigger={['click']}
    >
      <Button
        icon={<DotsIcon className={clsx(styles.icon, isOpen && styles.icon__active)} />}
        variant="flat"
        className={styles.iconButton}
      />
    </Tooltip>
  );
});
